import React, { Component } from 'react';
import { Container as Grid, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Radium, { StyleRoot } from 'radium';
import scrollToComponent from 'react-scroll-to-component';
import { Fade } from 'react-reveal';
import Container from './Container';
import ResourceUrl from '../resource';
import downArrow from '../assets/down.png';
import {Helmet} from 'react-helmet'
import Footer from './Footer';
import Loading from 'react-progress-bar-plus'


export default class CareerDefault extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      careers: [],
      positionFixed: false,
      showBanner: true,
      showLoading: true,
      loadingStatus: 0,
    };
    this.renderPositions = this.renderPositions.bind(this);
    this.triggerScroll = this.triggerScroll.bind(this);
  }
  renderPositions() {
    let careersList = [];
    let careers = this.state.careers;
    let delay = 0;
    if (careers.length > 0) {
      for (let i = 0; i < careers.length; i++) {
        careersList.push(
          <Col key={i} md={4} xs={12}  >
            <Fade bottom duration={700} delay={delay} key={'fade'+i} >
              <Link to={`/careers/${careers[i].slug}`} key={'link'+i} style={{ marginBottom: 20, paddingBottom: 20 }}  >
              <span key={'sp'+i}>{['/', careers[i].acf.location].join(' ')}</span>
              <h2 className={'position-title'} key={"p"+i} >{careers[i].title.rendered}</h2>
              <div className={'categories'} key={'cat'+i} >
                <span key={'span'+i}>{careers[i].acf.category.join(', ')}</span>
              </div>
            </Link>
          </Fade>
        </Col>
      );
      delay += 150;
    }
  } else {
    careersList.push(<div />);
  }
  return careersList;
}

componentDidMount() {
  axios
  .get(ResourceUrl.url + 'careers')
  .then(res => {
    this.setState({
      careers: res.data,
      loadingStatus: 50
    });
    this.hideLoader(this)
  })

  window.addEventListener('scroll', this.triggerScroll);
}
componentWillUnmount() {
  window.removeEventListener('scroll', this.triggerScroll);
}
hideLoader = (_this) => {
  setTimeout(function(){
    _this.setState({
      loadingStatus: 100
    });
    setTimeout(function(){
      _this.setState({
        showLoading: false
      })
    }, 200);
  }, 700);
}

triggerScroll() {
  let careers = document.getElementById('careers');
  if(careers){
    let scrollview = careers.getBoundingClientRect().bottom;
    if (scrollview < window.innerHeight + 100) {
      this.setState({ showBanner: false });
    } else {
      this.setState({ showBanner: true });
    }
  }
}
render() {
  const {showBanner, bannerHeight, isLoading, showLoading, loadingStatus} = this.state;

  return (
    <div>
      <Helmet>
        <title>Wunderfauks | An Integrated Creative Agency | Careers - Be A Part of Us</title>
        <meta name="description" content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses." />
        <meta name="keywords" content="integrated creative agency,digital marketing solutions,marketing communications,advertising agency,social media and communications,brand strategy,mobile development" />
        <meta property="og:title" content="" />
        <meta property="og:image" content="" />
        <meta property="og:description" content="" />
        <meta itemprop="name" content="" />
        <meta itemprop="description" content="" />
        <meta itemprop="image" content="" />
      </Helmet>
      {showLoading ?
        <div className={"loader"}   >
          <Loading
            percent={loadingStatus}
            spinner={false}
            autoIncrement={true}
            intervalTime={50}
          />
        </div>
        :

        <div>
          <div className={'banner-static'} style={{ zIndex: showBanner ? '-1' : '-3' }}>
            <Container title='ALWAYS LOOKING FOR' title1='WUNDER-FUL PEOPLE.' subtitle={"Let's get our hands dirty."} buttonText={'Openings'} />
            <button
              className={'down-button'}
              onClick={() => scrollToComponent(this.careers, { offset: 0, align: 'top', duration: 1000 })}>
              <img src={downArrow} alt={'down arrow'} />
            </button>
          </div>
          <div
            ref={section => {
              this.careers = section;
            }}
            id={'careers'}
            className='content-scrollview children'
            >
              <Grid className={'careers-list'}>
                <Row>{this.renderPositions()}</Row>
              </Grid>

            </div>
          </div>
        }
      </div>

    );
  }
}
