import React, { Component } from "react";
import axios from "axios";
import { Container as Grid, Row, Col } from "react-bootstrap";
import Radium, { StyleRoot } from "radium";
import { fadeInUp } from "react-animations";
import scrollToComponent from "react-scroll-to-component";
import { Fade } from "react-reveal";
import Container from "./Container";
import ResourceUrl from "../resource";
import downArrow from "../assets/down.png";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Loading from "react-progress-bar-plus";
import { Link } from "react-router-dom";

const styles = {
  fadeInOnly: {
    animation: "x 0.3s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeIn"),
  },
};

export default class CareerInner extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      location: "",
      category: [],
      details: "",
      requirements: [],
      showBanner: true,
      bannerHeight: 0,
      showLoading: true,
      loadingStatus: 0,
    };
    this.triggerScroll = this.triggerScroll.bind(this);
  }

  componentDidMount() {
    axios
      .get(ResourceUrl.url + "careers?slug=" + this.props.match.url.slice(9))
      .then((result) => {
        const career = result.data[0];
        this.setState({
          title: career.title.rendered,
          location: career.acf.location,
          category: career.acf.category,
          details: career.content.rendered,
          requirements: career.acf.requirements,
          loadingStatus: 40,
        });
        this.hideLoader(this);
      });

    window.addEventListener("scroll", this.triggerScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.triggerScroll);
  }
  hideLoader = (_this) => {
    setTimeout(function () {
      _this.setState({
        loadingStatus: 100,
      });
      setTimeout(function () {
        _this.setState({
          showLoading: false,
        });
      }, 200);
    }, 700);
  };

  triggerScroll() {
    let scrollview = document.getElementById("content-scrollview");
    if (!scrollview) {
      return;
    }
    scrollview = scrollview.getBoundingClientRect().bottom;
    if (scrollview < window.innerHeight + 100) {
      this.setState({ showBanner: false });
    } else {
      this.setState({ showBanner: true });
    }
  }
  applyPosition() {
    window.location.href = "mailto:jobs@wunderfauks.com";
  }
  render() {
    const { showBanner, bannerHeight, loadingStatus, showLoading, details } =
      this.state;

    return (
      <StyleRoot>
        <Helmet>
          <title>
            Wunderfauks | An Integrated Creative Agency | Career -{" "}
            {this.state.title}
          </title>
          <meta
            name="description"
            content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses."
          />
          <meta
            name="keywords"
            content="integrated creative agency,digital marketing solutions,marketing communications,advertising agency,social media and communications,brand strategy,mobile development"
          />
        </Helmet>
        {showLoading && (
          <div className={"loader"}>
            <Loading
              percent={loadingStatus}
              spinner={false}
              autoIncrement={true}
              intervalTime={50}
            />
          </div>
        )}
        {this.state.title == "" ? (
          <div id="loadingScreen" />
        ) : (
          <div>
            <div
              className="banner-static"
              style={{ zIndex: showBanner ? "-1" : "-3" }}
            >
              {!showLoading && (
                <Container
                  title={this.state.title}
                  subtitle={this.state.location}
                  buttonText={"Details"}
                />
              )}
              <button
                className={"down-button"}
                onClick={() =>
                  scrollToComponent(this.details, {
                    offset: 0,
                    align: "top",
                    duration: 1000,
                  })
                }
              >
                <img src={downArrow} alt={"down arrow"} />
              </button>
            </div>
            <div
              id="content-scrollview"
              className="career-inner children"
              ref={(section) => {
                this.details = section;
              }}
            >
              <Grid>
                <Row>
                  <Fade bottom>
                    <Col md={8} xs={12}>
                      <span className={"categories"}>
                        {this.state.category.join(", ")}
                      </span>
                      <h1 className={"title"}>{this.state.title}</h1>
                      <p
                        className="details"
                        dangerouslySetInnerHTML={{ __html: details }}
                      ></p>
                    </Col>
                    <Col md={3} xs={12}>
                      <a id="apply-btn" href="mailto:jobs@wunderfauks.com">
                        APPLY
                      </a>
                    </Col>
                    <Col md={1} />
                  </Fade>
                </Row>
                <br /> <br />
                <Row>
                  <Col md={12}>
                    <Link to={"/careers"} id="back-to-careers">
                      BACK TO CAREERS
                    </Link>
                  </Col>
                </Row>
              </Grid>
            </div>
          </div>
        )}
      </StyleRoot>
    );
  }
}
