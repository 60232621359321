import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Col, Row, Container, Image } from 'react-bootstrap';
import { fadeInUp } from 'react-animations';
import { Fade } from 'react-reveal';
import Radium, { StyleRoot } from 'radium';
import ResourceUrl from '../resource';
import Footer from './Footer';
import Loading from 'react-progress-bar-plus'


const styles = {
   fadeInOnly: {
      animation: 'x 1s ease',
      animationName: Radium.keyframes(fadeInUp, 'fadeIn'),
   },
};

export default class WorkDefault extends Component {


   constructor(props) {
      super(props);
      this.state = {
         work: [],
         showBanner: true,
         divHeight: '',
         showLoading: true,
         loadingStatus: 0,
      };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.triggerArrows = this.triggerArrows.bind(this);
   }
   componentDidMount() {
      axios
      .get(ResourceUrl.url + 'work')
      .then(res => {
         const work = res.data;
         this.setState({
            work: work,
            loadingStatus: 40,
         });
         let _this = this
         setTimeout(function(){
            _this.setState({
               loadingStatus: 100
            });
            setTimeout(function(){
               _this.setState({
                  showLoading: false,
               })
            }, 400);
         }, 1000);
      })
      setTimeout(function () {
         window.scrollTo(0, 0);
      }, 400);
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.addEventListener('scroll', this.triggerArrows);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
      window.removeEventListener('scroll', this.triggerArrows);
   }
   updateWindowDimensions() {
      let windowWidth = window.innerWidth
      let divHeight = 0
      if(windowWidth > 1000){
         divHeight = (windowWidth/3) * (9/16)
      }else{
         divHeight = (windowWidth) * (9/16)
      }
      this.setState({divHeight});
   }
   triggerArrows(event) {
      let scrollview = document.getElementById('content-scrollview');
      if(!scrollview){
         return;
      }
      let scrollviewT = scrollview.getBoundingClientRect().bottom;
      if (scrollviewT < window.innerHeight + 100 ) {
         this.setState({ showBanner: false });
      } else {
         this.setState({ showBanner: true });
      }
   }
   render() {
      const { work, showBanner, divHeight, showLoading, loadingStatus } = this.state;

      return (
         <div>
            {showLoading &&
               <div className={"loader"}   >
                  <Loading
                     percent={loadingStatus}
                     spinner={false}
                     autoIncrement={true}
                     intervalTime={50}
                  />
               </div>
            }

            { (work.length) == 0 ?
               <div id="loadingScreen" />
               :
               <div>
                  <div >
                     <div id="banner-static" style={{ zIndex: showBanner ? '-1' : '-3', height: '200px' }} />
                  </div>
                  <div className={'work-list'} id="content-scrollview"
                     style={{paddingBottom: '0px', background: '#1a181b'}}  >
                     <Container fluid={true}>
                        <Row>
                           <div>
                              {this.state.work.map( (post, i) => (
                                 <Col md={4} xs={12} key={i}>
                                    <Fade bottom duration={700} delay={((i+1)*20)} key={'fade'+i}>
                                       <div style={{ position: 'relative' }} >
                                          <Link to={`/work/${post.slug}`} key={post.id}
                                          >
                                             <Image src={post.acf.image.sizes.medium_large} alt={post.acf.image.alt} responsive="true" />
                                             <div className="work-cover" />
                                             <h1 className="work-titles" >{post.title.rendered}</h1>
                                             <p className="work-subtitles" >{post.acf.work_category}</p>
                                             {i < 10
                                                ?
                                                <span style={{height: divHeight+'px', lineHeight: divHeight+'px', zIndex: '1'}} className='work-cover-num'>
                                                   <p style={{margin:'0',height: divHeight+'px', lineHeight: divHeight+'px'}}>0{i+1}</p>
                                                </span>
                                                :
                                                <span style={{height: divHeight+'px', lineHeight: divHeight+'px', zIndex: '1'}} className='work-cover-num'>
                                                   <p style={{margin:'0',height: divHeight+'px', lineHeight: divHeight+'px'}}>{i+1}</p>
                                                </span>
                                             }

                                          </Link>
                                       </div>
                                    </Fade>
                                 </Col>
                              ))}
                           </div>
                        </Row>
                     </Container>

                  </div>

               </div>
            }
         </div>
      )
   }
}
