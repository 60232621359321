import React, { Component } from 'react';
import MtSvgLines from 'react-mt-svg-lines';

class Arrow3 extends Component {
  constructor() {
    super();
    this.state = {
      hidden: 'hidden',
    }
  }
  componentWillMount() {
    let self = this;
    setTimeout(() => {
      self.show();
    }, self.props.wait)
  }
  show() {
    this.setState({
      hidden: 'show',
    })
  }
  render() {
    return (
      <div className={ this.state.hidden }>
        <MtSvgLines animate={true} duration={500}>
          <svg width="83.563px" height="21.692px">
            <g>
              <polyline fill="none" stroke="#808080" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"
                points="68.718,1.5 78.063,10.846 68.718,20.192"/>
            </g>
          </svg>
        </MtSvgLines>
      </div>
    )
  }
}

export default Arrow3;
