import React, { Component } from 'react';

export default class StudioClock extends Component {
  static propTypes = {

  };

  constructor() {
    super();
    this.state = {
      date: this.getDate(),
      timeInterval: 0,
    }
    this.updateDate = this.updateDate.bind(this);
    this.updateClock = this.updateClock.bind(this);
  }
  getDate() {
    let newDate = new Date();
    let date = {};
    date.hours = newDate.getHours();
    date.minutes = newDate.getMinutes();
    date.seconds = newDate.getSeconds();
    return date;
  }
  updateDate() {
    this.setState({
      date: this.getDate(),
    })
  }
  updateClock() {
    let angleSeconds = (this.state.date.seconds * 60) / 10;
    let angleMinutes = this.state.date.minutes * 6;
    let angleHours = ((this.state.date.hours / 12) * 360);

    let SGseconds = this.refs.SGSec;
    let SGminute = this.refs.SGMin;
    let SGhour = this.refs.SGHr;

    let KHseconds = this.refs.KHSec;
    let KHminute = this.refs.KHMin;
    let KHhour = this.refs.KHHr;

    if (SGseconds && SGminute && SGhour) {
      SGseconds.style.transform = "rotate(" + angleSeconds + "deg)";
      SGminute.style.transform = "rotate(" + angleMinutes + "deg)";
      SGhour.style.transform = "rotate(" + (angleHours + 90) + "deg)";
    }
    if (KHseconds && KHminute && KHhour) {
      KHseconds.style.transform = "rotate(" + angleSeconds + "deg)";
      KHminute.style.transform = "rotate(" + angleMinutes + "deg)";
      KHhour.style.transform = "rotate(" + (angleHours + 60) + "deg)";
    }
  }
  componentDidMount() {
    this.setState({
      timeInterval: setInterval(() => {
        this.updateDate();
        this.updateClock();
      }, 1000),
    })
  }
  componentWillUnmount() {
    clearInterval(this.state.timeInterval);
  }
  render() {
    const { country } = this.props;
    let countryName = country.name;
    let countryShort;
    if (countryName === 'Singapore') {
      countryShort = 'SG';
    } else {
      countryShort = 'KH';
    }
    return (
      <div className={'clock'}>
        <div className={'twelve'} />
        <div className={'three'} />
        <div className={'six'} />
        <div className={'nine'} />
        <div ref={countryShort + 'Min'} className={'minute'} />
        <div ref={countryShort + 'Sec'} className={'seconds'} />
        <div ref={countryShort + 'Hr'} className={'hour'} />
      </div>
    );
  }
}
