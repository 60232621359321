import React, { Component } from 'react';
import MtSvgLines from 'react-mt-svg-lines';

class Arrow3 extends Component {
  constructor() {
    super();
    this.state = {
      hidden: 'hidden',
    }
  }
  componentWillMount() {
    let self = this;
    setTimeout(() => {
      self.show();
    }, self.props.wait)
  }
  show() {
    this.setState({
      hidden: 'show',
    })
  }
  render() {
    return (
      <div className={ this.state.hidden }>
        <MtSvgLines animate={true} duration={400}>
          <svg width="109.147px" height="99.682px">
            <g>
              <path fill="none" stroke="#808080" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" d="
                M107.647,1.5C96.854,50.345,52.636,87.724,10.5,89.176"/>
                {/* M10.5,89.176C52.636,87.724,96.854,50.345,107.647,1.5 */}
            </g>
          </svg>
        </MtSvgLines>
      </div>
    )
  }
}

export default Arrow3;
