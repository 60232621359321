import React, { Component } from 'react';
import Radium, { StyleRoot } from 'radium';
import { fadeInUp } from 'react-animations';


const styles = {
  fadeInAni: {
    animation: 'x 1s ease',
    animationName: Radium.keyframes(fadeInUp, 'fadeIn'),
  },
};
export default class Container extends Component {


  ContainerContent() {
    if (this.props.title) {
      return (
        <StyleRoot>
          <div style={styles.fadeInAni}>
            <div className={'std-container'}>
              <h1 className={'header'}>{this.props.title}</h1>
              <h1 className={'header'}>{this.props.title1}</h1>
              <p className={'subtitle'}>{this.props.subtitle}</p>
            </div>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <div id={'img-container'}>
          <img src={this.props.imgUrl} alt={'Background'} style={{backgroundColor: '#1a181b'}}  />
        </div>
      );
    }
  }
  render() {
     // const {is_image} = this.props
    return (
      <div className='inner-page-container'  >
        {this.ContainerContent()}
        <div className="bottom-arrow">
          <span>{this.props.buttonText}</span>
        </div>
      </div>
    );
  }
}
