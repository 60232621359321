import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Radium, { StyleRoot } from 'radium';
import { fadeInDown } from 'react-animations';
import logoUrl from '../images/wunderfauks_logo.png';
import Menu from './MenuHead';



export default class Header extends Component {

   constructor() {
      super()
      this.state = {
         cover: false
      }
      this.triggerHeadCover = this.triggerHeadCover.bind(this)
   }
   componentDidMount() {
      window.addEventListener('scroll', this.triggerHeadCover);
   }

   triggerHeadCover() {
      let top = window.pageYOffset || document.documentElement.scrollTop;
      let height = window.innerHeight;
      if(top > height/3 ){
         this.setState({
            cover: true
         })
      }else{
         this.setState({
            cover: false
         })
      }
   }

   render() {
      const {cover} = this.state
      const studios = [
         {
            name: 'Singapore',
            address: {
               'street-address': '321B Beach Road, #03-00',
               locality: 'Singapore 199557',
               'postal-code': '',
               region: '',
            },
            timezone: {
               id: 'Asia/Singapore',
            },
            email: 'contact@wunderfauks.com',
            phone: '+65 6635 2236',
         },
         {
            name: 'Cambodia',
            address: {
               'street-address': 'Le Urban Eco Park, Road 3,',
               locality: '27km, Khandal Steung District,',
               'postal-code': 'Khandal Province, Cambodia',
               region: '',
            },
            timezone: {
               id: 'Asia/Phnom_Penh',
               offset: 1,
            },
            email: 'contact@wunderfauks.com',
            phone: '+65 6635 2236',
         },
      ];
      let pathname = window.location.pathname;

      return (
         <StyleRoot>
            <div id={"header-wrap"}>
               <Menu studios={studios}  />
               {pathname !== '/' ?
               <Link to='/' id={"logo-link"} >
               <img src={logoUrl} id="logo" width={'180'} height={'100%'} alt={'header logo'} />
               {/* <img src='https://staging-back.wunderfauks.com/wp-content/uploads/2019/02/wunderfauks_logo.png' id="logo" width={'180'} height={'100%'} alt={'header logo'} /> */}
            </Link>
            :
            <a href='/' id={"logo-link"} >
            <img src={logoUrl} id="logo" width={'180'} height={'100%'} alt={'header logo'} />
            {/* <img src='https://staging-back.wunderfauks.com/wp-content/uploads/2019/02/wunderfauks_logo.png' id="logo" width={'180'} height={'100%'} alt={'header logo'} /> */}
         </a>
      }

   </div>

   {cover &&
      <div id="header-cover"  > </div>
   }

</StyleRoot>
);
}
}
