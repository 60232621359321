import React, { Component } from 'react';
import { fadeInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { Grid, Row, Col } from 'react-bootstrap';
import {Helmet} from 'react-helmet'
import Footer from './Footer'
import { Link } from 'react-router-dom';
import $ from 'jquery'
import StudioContact from './StudioContact';
import scrollToComponent from 'react-scroll-to-component';


const styles = {
   fadeInOnly: {
      animation: 'x 1s ease',
      animationName: Radium.keyframes(fadeInUp, 'fadeIn'),
   },
};

export default class CareerDefault extends Component {


   componentDidMount() {
      if(window.innerWidth > 767){
         this.props.history.push('/contact#about')
      }
      let hash = window.location.hash.substr(1)
      $('#parent > .footer-wrap').css({ display: 'none' })
      let _this = this
      setTimeout( () => {
         if(hash == 'contact'){
            // window.scrollTo(0, document.body.scrollHeight);
            scrollToComponent(_this.footerDiv, {
               align: 'top',
               duration: 1000,
               ease: 'inOutQuint'
            });
         }
      }, 1000)
      setTimeout( () => {
         $('#main-content').css({ marginBottom: 0 })
      }, 700)
   }

   componentWillUnmount() {
      $('#parent > .footer-wrap').css({ display: 'block' })
   }

   render() {
      const studios = this.props.studios;
      let pathname = window.location.pathname;

      return (
         <StyleRoot>
            <Helmet>
               <title>Wunderfauks | An Integrated Creative Agency | About</title>
               <meta name="description" content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses." />
               <meta name="keywords" content="integrated creative agency,digital marketing solutions,marketing communications,advertising agency,social media and communications,brand strategy,mobile development" />
               <meta property="og:title" content="" />
               <meta property="og:image" content="" />
               <meta property="og:description" content="" />
               <meta itemprop="name" content="" />
               <meta itemprop="description" content="" />
               <meta itemprop="image" content="" />
            </Helmet>
            <div id="about-div"  >
               <div id="about-content"  >
                  <h1 style={{ fontWeight: 'normal', color: '#666666', fontSize: 18 }} >About</h1>
                  <p style={[styles.fadeInOnly,{color: '#ccc'}]}>Wunderfauks is an integrated creative agency focusing on new and innovative experiences. From the likes of
                     creative expression to a campaign execution, communication and creative implementation, Wunderfauks provides
                     tailored bulls-eye solutions that focus on results over activities.
                  </p>
                  <p style={[styles.fadeInOnly,{color: '#ccc'}]}>With digital as our strong suit, we have a dynamic team comprising of multi-disciplinary individuals with
                     their own think tanks of interesting ideas and concepts to suit any need, logic and aspiration.
                  </p>
               </div>


               <div className='footer-wrap container-fluid' id='footer-wrap' ref={i => this.footerDiv = i}  >
                  <Row  >
                     <Col md={12} xs={12}>
                        <h1 className={'footer-discuss'}>Interested? Lets Discuss.</h1>
                     </Col>
                     <Col md={10} xs={12} className={'locations'}>
                        <Row className={'studios'}>
                           {studios && studios.map((studio, i) => {
                              return (
                                 <Col key={i} className="footer-clock-div" md={5}>
                                    <StudioContact location={'footer'} studio={studio} />
                                 </Col>
                              );
                           })}
                        </Row>
                     </Col>
                     <Col md={2} xs={12} className='container right-menus'>

                        <div className='soc-med visible-xs'  >
                           <div>
                              <a href="https://www.facebook.com/Wunderfauks/" target="_blank" rel="noopener noreferrer"
                              >
                                 <div className="facebook" />
                              </a>
                           </div>

                           <div>
                              <a href="https://www.instagram.com/wunderfauks/" target="_blank" rel="noopener noreferrer"
                              >
                                 <div className="instagram" />
                              </a>
                           </div>
                           <div>
                              <a href="https://sg.linkedin.com/company/wunderfauks" target="_blank" rel="noopener noreferrer"
                              >
                                 <div className="linkedin" />
                              </a>
                           </div>
                        </div>

                        <Row className='footer-menu'>

                           <Col md={12}  className="col-xs-5ths" style={{ display: window.innerWidth < 767 ? 'inline-block' : 'none' } } >
                              {
                                 pathname == '/about' ?
                                 <a href="#">About</a>
                                 :
                                 <Link to={'/about'} >About</Link>
                              }
                           </Col>

                           <Col md={12}  className="col-xs-5ths" style={{ display: window.innerWidth > 767 ? 'block' : 'none' } } >
                              {
                                 <p onClick={this.handleAbout}>About</p>
                              }
                           </Col>

                           <Col md={12}  className="col-xs-5ths" >
                              {
                                 pathname == '/work' ?
                                 <a href="#" >Work</a>
                                 :
                                 <Link to={'/work'}>Work</Link>
                              }
                           </Col>

                           <Col md={12}  className="col-xs-5ths" >
                              {
                                 pathname == '/services' ?
                                 <a href="#" >Services</a>
                                 :
                                 <Link to={'/services'}>Services</Link>
                              }
                           </Col>
                           <Col md={12} className="col-xs-5ths" >
                              {
                                 pathname == '/services' ?
                                 <a href="/services#approach" >Approach</a>
                                 :
                                 <Link to={'/services#approach'}>Approach</Link>
                              }
                           </Col>
                           <Col md={12} className="col-xs-5ths" >
                              {
                                 pathname == '/careers' ?
                                 <a href="#" >Careers</a>
                                 :
                                 <Link to={'/careers'}>Careers</Link>
                              }
                           </Col>
                        </Row>
                        <div className='soc-med hidden-xs'  >
                           <div>
                              <a href="https://sg.linkedin.com/company/wunderfauks" target="_blank" rel="noopener noreferrer"
                              >
                                 <div className="linkedin" />
                              </a>
                           </div>

                           <div>
                              <a href="https://www.instagram.com/wunderfauks/" target="_blank" rel="noopener noreferrer"
                              >
                                 <div className="instagram" />
                              </a>
                           </div>
                           <div>
                              <a href="https://www.facebook.com/Wunderfauks/" target="_blank" rel="noopener noreferrer"
                              >
                                 <div className="facebook" />
                              </a>
                           </div>
                        </div>
                     </Col>
                  </Row>
                  <div className={'bottom'}>
                     <div className={'copyright'}>© {new Date().getFullYear()}</div>
                     <div className={'slogan'}>An Integrated Creative Agency.</div>
                  </div>
               </div>


            </div>

         </StyleRoot>
      );
   }
}
