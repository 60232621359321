import React, { Component } from 'react';
import axios from 'axios';
import he from 'he';
import { Container as Grid, Row, Col } from 'react-bootstrap';
import { fadeInUp, fadeInUpBig, fadeIn, fadeInLeft } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import { Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import { Fade } from 'react-reveal';
import Container from './Container';
import Footer from './Footer';

import ResourceUrl from '../resource';
import downArrow from '../assets/down.png';
import Loader from 'react-loaders';
import Slider from 'react-slick';
import {Helmet} from 'react-helmet'
import { Image } from 'react-bootstrap'
import ArrowLeft from '../images/arrow-left.png'
import ArrowRight from '../images/arrow-right.png'
import Loading from 'react-progress-bar-plus'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor'

//
// const NextArrow = (props) => {
//    const {className, style, onClick} = props
//    return (
//       <button className={className} onClick={onClick}></button>
//    );
// }
// const PrevArrow = (props) => {
//    const {className, style, onClick} = props
//    return (
//       <button  className={className} onClick={onClick}>Left</button>
//    )
//
// }


const styles = {
   parent: {
      animation: 'x 4s ease',
      animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
      backgroundColor: '#f4f4f4',
   },
   fadeIn: {
      animation: 'x 10.5s ease',
      animationName: Radium.keyframes(fadeIn, 'fadeIn'),
   },
   fadeInUp: {
      animation: 'x 1s ease',
      animationName: Radium.keyframes(fadeIn, 'fadeIn'),

   },
   fadeInPro: {
      animation: 'x 1.5s ease' ,
      animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
   },
   button: {
      background: 'transparent',
      border: 'none',
      position: 'absolute',
      left: '0',
      right: '0',
      width: '100%',
      transform: 'translateY(-125%)',
      ':focus': {
         outline: 'none',
      },
   },
};

export default class WorkInner extends Component {
   static propTypes = {};

   constructor() {
      super();
      this.state = {
         nextWork: [],
         category: '',
         subCategory: '',
         title: '',
         client: '',
         main_image: '',
         main_description: '',
         work_contents: null,
         id: '',
         showBanner: true,
         bannerHeight: 0,
         snippet: '',
         logo: '',
         slick_settings: {},
         pro_width: 0,
         show_pro: false,
         loadingStatus: 0,
         showLoading: true,
         divHeight: 0,
         footerHeight: 0,
         mobileBannerHeight: 400,
      };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.triggerScroll = this.triggerScroll.bind(this);
   }


   componentDidMount() {
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 2;
      },800)
      setTimeout( () => {
         document.body.scrollTop = document.documentElement.scrollTop = 0;
      },1000)

      axios
      .get(ResourceUrl.url + "work?slug=" + this.props.match.url.slice(6))
      .then(res => {
         const work = res.data[0];
         let work_contents = work.acf.work_content_items

         this.setState({
            loadingStatus: 40,
            main_image: work.acf.image.url,
            main_description: work.content.rendered,
            category: work.acf.work_category,
            subCategory: work.acf.work_sub_category,
            title: work.title.rendered,
            client: work.acf.client,
            work_contents: work.acf.work_content_items,
            work_background: work.acf.background_color,
            id: work.id,
            logo: work.acf.logo

         });
         for (var i = 0; i < work_contents.length; i++) {
            if(work_contents[i].acf_fc_layout == 'layout_content_block'){
               let snippet_raw = work_contents[i].content_item
               let div = document.createElement("div");
               div.innerHTML = snippet_raw;
               let snippet = (div.textContent || div.innerText || "").substring(0,230) + ' ...'
               this.setState({
                  snippet
               })
               return;
            }
         }

      })

      axios
      .get(ResourceUrl.url + 'work')
      .then(res => {
         const allWorkArray = res.data;
         let currID = this.state.id;
         allWorkArray.forEach(each => {
            let pos = allWorkArray.indexOf(each);
            if (currID === each.id) {
               allWorkArray.splice(pos, 1);
            }
         });
         allWorkArray.sort(() => Math.random() - 0.5);
         let nextWork = allWorkArray.slice(0, 3);
         this.setState({
            loadingStatus: 50,
            nextWork: nextWork,
         });

         this.loadImage(this)

      })
      this.loadImageTimer = setInterval( ()=> {
         this.loadImage(this)
      }, 3000)
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      window.addEventListener('scroll', this.triggerScroll);

      let windowWidth = window.innerWidth
      if(windowWidth > 766){
         this.state.slick_settings = {
            dots: true,
            infinite: false,
            speed: 500,
            autoplay: false,
            autoplaySpeed: 3000,
            draggable: false,
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '100px',
         };
      }else{
         this.state.slick_settings = {
            dots: true,
            infinite: false,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 4000,
            draggable: true,
            centerMode: false,
            slidesToShow: 1,
         };
      }
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.triggerScroll);
      window.removeEventListener('resize', this.updateWindowDimensions);
      clearInterval(this.loadImageTimer)
   }
   updateWindowDimensions = () => {
      let footerHeight = '550px'
      let footer = document.getElementById('footer-wrap');
      if(footer){
         footerHeight = (footer.clientHeight - 50) + 'px'
      }else{
         let _this = this
         setTimeout(function(){
            _this.updateWindowDimensions()
         }, 500)
      }
      let windowWidth = window.innerWidth
      let divHeight = 0

      if(windowWidth > 1200){
         divHeight = ((windowWidth-90)/3) * (9/16)
         this.setState({bannerHeight: (window.innerHeight) + 'px',footerHeight, divHeight});
      }else{
         divHeight = (windowWidth-30) * (9/16)
         this.setState({bannerHeight: (this.state.mobileBannerHeight) + 'px',footerHeight, divHeight});
      }
   }

   getBgUrl = (el) => {
      var bg = "";
      if (el && el.currentStyle) {
         bg = el.currentStyle.backgroundImage;
      } else if (el && document.defaultView && document.defaultView.getComputedStyle) { // Firefox
         // console.log(document.defaultView.getComputedStyle(el, ""))
         bg = document.defaultView.getComputedStyle(el, "").backgroundImage;
      } else if(el) {
         bg = el.style.backgroundImage;
      }
      return bg.replace(/url\(['"]?(.*?)['"]?\)/i, "$1");
   }
   loadImage = (_this) => {
      let image = document.createElement('img');
      image.src = this.getBgUrl(document.getElementById('container-background'));
      image.onload = function () {
         let height = image.height * ( window.innerWidth/image.width)
         _this.setState({ mobileBannerHeight: height - 5 })
         setTimeout(function(){
            _this.setState({
               loadingStatus: 100
            });
            setTimeout(function(){
               _this.setState({
                  showLoading: false
               })
            }, 200);
         }, 1200);
         _this.updateWindowDimensions();
      };
   }

   triggerScroll() {
      let scrollview = document.getElementById('content-scrollview')
      if(scrollview){
         let scrollviewTop = scrollview.getBoundingClientRect().bottom;
         if (scrollviewTop < window.innerHeight + 100 ) {
            this.setState({ showBanner: false });
         } else {
            this.setState({ showBanner: true });
         }
      }
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let nextUp = document.getElementById('next-up')
      if(nextUp) {
         let nextUpTop = nextUp.offsetTop
         let pro_width = (scrollTop/nextUpTop)*100
         this.setState({
            pro_width
         })
      }
      let height = window.innerHeight;
      if(scrollTop > height/2 ){
         this.setState({
            show_pro: true
         })
      }else{
         this.setState({
            show_pro: false
         })
      }

   }

   handleNumberRender = (text) => {
      let percentage = text.substr(text.length - 1)
      if(percentage == '%'){
         text = text.slice(0, -1)
         return(
            <VisibilitySensor>
               {
                  ({isVisible}) =>
                  <div>
                     { isVisible ? <h3><CountUp start={0} end={text} />%</h3>
                     :<h3>0%</h3> }
                  </div>
               }
            </VisibilitySensor>
         )
      }else{
         return(
            <VisibilitySensor>
               {
                  ({isVisible}) =>
                  <div>
                     { isVisible ? <h3><CountUp start={0} end={text} /></h3>
                     :<h3>0</h3> }
                  </div>
               }
            </VisibilitySensor>
         )
      }

   }

   render() {
      const { showBanner, id, main_image, main_description, work_contents,work_background, bannerHeight, logo, client, slick_settings, show_pro, pro_width, loadingStatus, showLoading, footerHeight, divHeight } = this.state;

      let windowWidth = window.innerWidth

      return (
         <StyleRoot  >
            <Helmet>
               <title>Wunderfauks Work | An Integrated Creative Agency | {this.state.title}</title>
               <meta name="description" content={this.state.snippet} />
            </Helmet>
            {showLoading &&
               <div className={"loader"}   >
                  <Loading
                     percent={loadingStatus}
                     spinner={false}
                     autoIncrement={true}
                     intervalTime={50}
                  />
               </div>
            }

            {id == '' ?
            <div id="loadingScreen" />
            :
            <div >
               {show_pro &&
                  <div id="progression" style={styles.fadeInPro}>
                     <div id="pro-back" />
                     <div id="pro-front" style={{ width: pro_width+'vw' }} />
                  </div>
               }

               <div id="banner-static" style={{ zIndex: showBanner ? '-1' : '-3' }}>
                  <div id="banner-static1" />
                  <div style={{height: '100%'}}>

                     <div className="inner-page-container" style={{display: 'block'}} >
                        {showLoading ?
                           <div id="container-background" style={[styles.fadeInUp, { backgroundImage: `url(${main_image})` , display: 'none'}]} className="container-background" />
                           :
                           <div id="container-background" style={[styles.fadeInUp, { backgroundImage: `url(${main_image})` }]} className="container-background" />
                        }
                        <button
                           style={styles.button}
                           onClick={() => scrollToComponent(this.work, { offset: 0, align: 'top', duration: 1000 })} >
                           <div style={{color: '#676767', fontSize: '10px', fontFamily: 'OpenSans'}}>scroll down</div>
                           <img style={{ width: '60px' }} src={downArrow} alt={'down arrow'} />
                        </button>
                     </div>


                  </div>
               </div>

               <div id="content-scrollview"
                  ref={section => {this.work = section}}
                  style={{marginTop: bannerHeight, marginBottom: footerHeight, overflow: 'hidden', background: work_background }}>
                  <Fade >
                     <Grid>
                        <Row className={'work-inner'}>

                           <Col md={9} xs={12} className={'title'}>
                              <div>
                                 <p className={'category'}>/ {this.state.category}</p>
                                 <h1 className='work-title' >{this.state.title}</h1>
                                 <div className="main_description" dangerouslySetInnerHTML={{ __html: main_description }} />
                              </div>
                           </Col>

                           <Col md={3} xs={12} className="min-center hidden-xs">
                              {client &&
                                 <span>Client</span>
                              }
                              <p className={'name'} style={{lineHeight: 1.5}} >{client}</p>

                              {logo &&
                                 <Image src={logo.url} alt={logo.alt} className="logo-image" />
                              }
                           </Col>
                        </Row>
                     </Grid>
                  </Fade>

                  <div id="work-parent" >
                     {work_contents &&
                        work_contents.map((work_content, i) => {
                           let layout = work_content.acf_fc_layout;
                           if (layout == 'layout_content_block') {
                              return (
                                 <Fade key={i} >
                                    <div className="work_content_text" key={i}
                                       style={{backgroundColor: work_content.background_color, color: work_content.text_color}} >
                                       <div
                                          key={i} dangerouslySetInnerHTML={{ __html: work_content.content_item }}
                                       />
                                    </div>
                                 </Fade>
                              )
                           } else if (layout == 'layout_image_slider') {
                              return (
                                 <Fade key={i} >
                                    <div style={{backgroundColor: work_content.background_color}}
                                       className="work_content_slide_parent" key={i}>
                                       <div className="work_content_slide">
                                          <Slider key={i} {...slick_settings} >
                                             {work_content.image_slider_items.map( (image_slider_item, oo) => (
                                                <img
                                                   key={oo}
                                                   src={image_slider_item.image.url}
                                                   alt={image_slider_item.image.alt}
                                                   style={{ maxWidth: '100%' }}
                                                />
                                             ))}
                                          </Slider>
                                       </div>
                                    </div>
                                 </Fade>
                              );
                           } else if (layout == 'layout_single_image') {
                              return (
                                 <Fade key={i} >
                                    <div style={{backgroundColor: work_content.background_color}} className='work_content_single' >
                                       {work_content.single_image &&
                                          <img
                                             src={work_content.single_image.url}
                                             alt={work_content.single_image.alt}
                                             style={{ width: '100%' }}
                                          />
                                       }
                                       {work_content.video &&
                                          <video muted autoPlay loop width="100%" type="video/mp4" playsInline controls={false}
                                          src={work_content.video.url}
                                       />
                                    }
                                    {work_content.button_text && (
                                       <button className='btn-hover' onClick={() => window.open(work_content.link_url)}>
                                          {work_content.button_text}
                                       </button>
                                    )}
                                 </div>
                              </Fade>
                           );
                        }else if (layout == 'layout_fancy_block') {
                           return (
                              <Fade key={i} >
                                 <div style={{
                                    backgroundColor: work_content.background_color,
                                 }} className={'work_content_fancy ' + (work_content.position === 'Top' ? 'fancy-top' : work_content.position === 'Middle' ? 'fancy-middle' : 'fancy-bottom' )} >
                                 <img
                                    src={work_content.single_image.url}
                                    alt={work_content.single_image.alt}
                                    className={(work_content.position === 'Top' ? 'fancy-img-top' : work_content.position === 'Middle' ? 'fancy-img-middle' : 'fancy-img-bottom' )}
                                 />
                              </div>
                           </Fade>
                        );
                     }else if (layout == 'layout_advance_Container') {
                        return (
                           <Fade key={i} >
                              <div style={{
                                 backgroundColor: work_content.background_color,
                              }}
                              key={i}
                              className='work_content_advance' >
                              <Grid >
                                 <Row>
                                    <Col md={8} xs={12} className="advance-Container-left" >
                                       <h2>{work_content.title}</h2>
                                       <p>{work_content.subtitle}</p>

                                       <Row className="advance-Container-squares" >
                                          {work_content.left_images && work_content.left_images.map( (left_image,oo) => (
                                             <Col md={3} xs={6} key={oo} style={{padding: '0'}}>
                                                <img
                                                   key={oo}
                                                   src={left_image.image.url}
                                                   alt={left_image.image.alt}
                                                   width="100%"
                                                />
                                             </Col>
                                          ))}
                                       </Row>
                                    </Col>
                                    <Col md={4} xs={12} className="advance-Container-right" >
                                       <img
                                          src={work_content.right_image.url}
                                          alt={work_content.right_image.alt}
                                       />
                                    </Col>
                                 </Row>
                                 <Row className="footer-div">
                                    <Col md={4} xs={12} key={1}>
                                       {this.handleNumberRender(work_content.footer.footer_1_title)}
                                       <p>{work_content.footer.footer_1}</p>
                                    </Col>
                                    <Col md={4} xs={12} key={2}>
                                       {this.handleNumberRender(work_content.footer.footer_2_title)}
                                       <p>{work_content.footer.footer_2}</p>
                                    </Col>
                                    <Col md={4} xs={12} key={3}>
                                       {this.handleNumberRender(work_content.footer.footer_3_title)}
                                       <p>{work_content.footer.footer_3}</p>
                                    </Col>
                                 </Row>
                              </Grid>
                           </div>
                        </Fade>
                     );
                  }else if (layout == 'layout_video') {
                     return (
                        <Fade key={i}
                           >
                              <div style={{
                                 backgroundColor: work_content.background_color,
                              }} className='work_content_video' >
                              <iframe className="video-div" src={work_content.embed_url} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                           </div>
                        </Fade>
                     );
                  }else if (layout == 'layout_text_image') {
                     return (
                        <Fade key={i} >
                           <div className="work_content_text_image" key={i}
                              style={{backgroundColor: work_content.background_color}} >
                              <div className="work_content_text_image_text"
                                 key={i} dangerouslySetInnerHTML={{ __html: work_content.text }}
                              />
                              <img className="work_content_text_image_image" src={work_content.image.url} width="100%" />
                           </div>
                        </Fade>
                     )
                  }

               })}

            </div>
            <Fade >
               <div  id="next-up"  className='next container-fluid'>
                  <p className='header'>Next up?</p>
                  <Row>
                     {this.state.nextWork.map((each,i) => (
                        <Col md={4} key={each.id} style={{display: (i==0 && windowWidth < 768) ? 'block' : windowWidth > 767 ? 'block' : 'none'  }} >
                           <Link to={String(each.slug)} className='next-work' >
                              <Image src={each.acf.image.url} responsive='true' />
                              <div className="work-cover" />
                              <h1 className="hover-text-div-h1">{each.title.rendered}</h1>
                              <span className="hover-text-div-p">{each.acf.work_category}</span>

                              <span style={{height: divHeight+'px', lineHeight: divHeight+'px', zIndex: '1'}} className='work-cover-num'>
                                 <p style={{margin:'0',height: divHeight+'px', lineHeight: divHeight+'px'}}>0{i+1}</p>
                              </span>
                           </Link>
                        </Col>
                     ))}
                  </Row>
               </div>
            </Fade>
         </div>
      </div>
   }
</StyleRoot>
);
}
}
