import React, { Component } from "react";
import "./App.css";
import { hot } from "react-hot-loader";

import { StyleRoot } from "radium";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Home from "./pages/Home";
import Careers from "./pages/Careers";
import Work from "./pages/Work";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import "react-progress-bar-plus/lib/progress-bar.css";
import { TweenLite } from "gsap";
import $ from "jquery";
import Font from "./Font";
import SmeInitiative from "./pages/SmeInitiative ";

const studios = [
  {
    name: "Singapore",
    address: {
      "street-address": "321B Beach Road, #03-00",
      locality: "Singapore 199557",
      "postal-code": "",
      region: "",
      map: "https://www.google.com.sg/maps/place/Wunderfauks/@1.3013934,103.858976,17z/data=!3m1!4b1!4m5!3m4!1s0x31da196bcb6ffcdf:0xfe6ed6642cd5e760!8m2!3d1.301388!4d103.8611647",
    },
    timezone: {
      id: "Asia/Singapore",
    },
    email: "contact@wunderfauks.com",
    phone: "+65 6635 2236",
  },
  {
    name: "Cambodia",
    address: {
      "street-address": "Le Urban Eco Park, Road 3,",
      locality: "27km, Khandal Steung District,",
      "postal-code": "Khandal Province, Cambodia",
      region: "",
      map: "https://www.google.com/maps/place/Le+Urban+Eco+Park/@11.4109424,104.7899499,17z/data=!4m5!3m4!1s0x3109438411c3dedd:0x281e28a5f8dfb2f7!8m2!3d11.4109371!4d104.7921386",
    },
    timezone: {
      id: "Asia/Phnom_Penh",
      offset: 1,
    },
    email: "contact@wunderfauks.com",
    phone: "+65 6635 2236",
  },
];

let history = createBrowserHistory();

let historyObject = history.location.pathname;

const completeCall = (target) => {
  TweenLite.set(target, { clearProps: "position, width, transform" });
};

const WorkRender = (props) => {
  return <Work studios={studios} {...props} />;
};
const HomeRender = (props) => {
  return <Home studios={studios} {...props} />;
};
const CareersRender = (props) => {
  return <Careers studios={studios} {...props} />;
};
const ServicesRender = (props) => {
  return <Services studios={studios} {...props} />;
};
const AboutRender = (props) => {
  return <About studios={studios} {...props} />;
};
const ContactRender = (props) => {
  return <Contact studios={studios} {...props} />;
};

const Render404 = (props) => {
  return <Error studios={studios} {...props} />;
};

const SMEInitiative = (props) => {
  return <SmeInitiative studios={studios} {...props} />;
};

class App extends Component {
  constructor() {
    super();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.unlisten = history.listen((location, action) => {
      console.log("changing route");
      historyObject = location.pathname;
    });
  }
  componentDidMount() {
    Font();
    let _this = this;
    setTimeout(function () {
      _this.updateWindowDimensions();
    }, 500);
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.updateFooter();
  }

  updateFooter = () => {
    let footer = $("#footer-wrap");
    let pathname = window.location.pathname;
    if (footer && footer.height() && pathname != "/about") {
      let footerHeight =
        document.getElementById("footer-wrap").clientHeight - 50;
      $("#main-content").css({ marginBottom: footerHeight });
    } else {
      let _this = this;
      setTimeout(() => {
        _this.updateFooter();
      }, 100);
    }
  };

  render() {
    return (
      <Router>
        <Route
          render={({ location }) => (
            <div id="grandparent">
              <Route exact path="/" />
              <Header />

              <TransitionGroup>
                <Transition
                  key={location.key}
                  timeout={500}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  onEnter={(node) => {
                    TweenLite.killTweensOf(node);
                    setTimeout(() => {
                      document.body.scrollTop =
                        document.documentElement.scrollTop = 0;
                    }, 400);
                    const parent = node.parentNode;
                    const targetWidth =
                      parent.clientWidth -
                      parseFloat(
                        getComputedStyle(node.parentNode).paddingLeft
                      ) *
                        2;
                    TweenLite.set(node, {
                      autoAlpha: 0,
                      width: targetWidth,
                      display: "none",
                    });
                    TweenLite.to(node, 0.3, {
                      display: "block",
                      autoAlpha: 1,
                      onComplete: completeCall,
                      onCompleteParams: [node],
                      delay: 0.3,
                    });
                    setTimeout(() => {
                      this.updateWindowDimensions();
                    }, 500);
                  }}
                  onExit={(node) => {
                    TweenLite.killTweensOf(node);
                    const parent = node.parentNode;
                    const targetWidth =
                      parent.clientWidth -
                      parseFloat(
                        getComputedStyle(node.parentNode).paddingLeft
                      ) *
                        2;
                    TweenLite.set(node, {
                      width: targetWidth,
                    });
                    TweenLite.to(node, 0.3, {
                      opacity: 0,
                      autoAlpha: 0,
                    });
                  }}
                >
                  <div id="parent">
                    <div id="main-content">
                      <Switch location={location}>
                        <Route exact path="/" render={HomeRender} />
                        <Route path="/about" render={AboutRender} />
                        <Route path="/work" render={WorkRender} />
                        <Route path="/careers" render={CareersRender} />
                        <Route path="/services" render={ServicesRender} />
                        <Route path="/contact" render={ContactRender} />
                        <Route path="/sme-initiative" render={SMEInitiative} />
                        <Route render={Render404} />
                      </Switch>
                    </div>
                    <Footer studios={studios} />
                  </div>
                </Transition>
              </TransitionGroup>
            </div>
          )}
        />
      </Router>
    );
  }
}

export default hot(module)(App);
