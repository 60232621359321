import React, { Component } from 'react';
import { fadeInUp } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import {Helmet} from 'react-helmet'
import ErrorImg from '../assets/fail.jpg'
import { Link } from 'react-router-dom';
import PointsAnimation from './PointsAnimation'

export default class ErrorDefault extends Component {

   constructor(props) {
      super(props)
      this.state = {
         pMouseX: 0,
         pMouseY: 0,
         pMouseUp: 0,
         pMouseDown: 0
      }
      this.motionHandler = this.motionHandler.bind(this);
   }

   componentDidMount() {
      this.windowHalfX = window.innerWidth / 2;
      this.windowHalfY = window.innerHeight / 2;
      if(window.DeviceMotionEvent){
        window.addEventListener("devicemotion", this.motionHandler, false);
      }
   }
   motionHandler(event){
     const {pointsZoom} = this.state

     if(pointsZoom){
       this.setState({
         pMouseX: event.accelerationIncludingGravity.x * 30,
         pMouseY: event.accelerationIncludingGravity.y * 30
       })
     }
   }
   _onMouseMove(e) {
     this.setState({
      pMouseX: e.clientX - this.windowHalfX,
      pMouseY: e.clientY - this.windowHalfY
     })
   }
   _onMouseDown() {
     this.setState({
      pMouseUp: false,
      pMouseDown: true,
     })
   }
   _onMouseUp() {
     this.setState({
      pMouseUp: true,
      pMouseDown: false,
     })
   }



   render() {
      const {pMouseX, pMouseY, pMouseUp, pMouseDown} = this.state

      return (
         <StyleRoot>
            <Helmet>
               <title>Wunderfauks | An Integrated Creative Agency | About</title>
               <meta name="description" content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses." />
               <meta name="keywords" content="integrated creative agency,digital marketing solutions,marketing communications,advertising agency,social media and communications,brand strategy,mobile development" />
               <meta property="og:title" content="" />
               <meta property="og:image" content="" />
               <meta property="og:description" content="" />
               <meta itemprop="name" content="" />
               <meta itemprop="description" content="" />
               <meta itemprop="image" content="" />
            </Helmet>

            <div id="error-div"
               onMouseMove = {this._onMouseMove.bind(this)}
               onMouseDown = {this._onMouseDown.bind(this)}
               onTouchStart = {this._onMouseDown.bind(this)}
               onMouseUp = {this._onMouseUp.bind(this)}
               onTouchEnd = {this._onMouseUp.bind(this)}
               >
                  <div id="error-texts">
               <h1>Error 404</h1>
               <h2>Looks like something went wrong.</h2>
               {/* <img src={ErrorImg} /> */}
               <h2>Click <Link to={'/'} >here</Link> instead.</h2>
               {/* <h1>You've hit so hard</h1>
               <h2>Go Back <Link to={'/'} >Home</Link> and rest</h2> */}
            </div>
               <div id="error-points">
               <PointsAnimation pMouseX={pMouseX} pMouseY={pMouseY} pMouseUp={pMouseUp} pMouseDown={pMouseDown} zoom={true} />
            </div>
            </div>

         </StyleRoot>
      );
   }
}
