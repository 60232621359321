import React, { Component } from "react";
import { Route } from "react-router-dom";
import WorkDefault from "./WorkDefault";
import WorkInner from "./WorkInner";
import { Helmet } from "react-helmet";

class Work extends Component {
  render() {
    return (
      <div className={"work-component"}>
        <Helmet>
          <title>
            Wunderfauks | An Integrated Creative Agency | Works and Portfolios
          </title>
          <meta
            name="description"
            content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses."
          />
          <meta
            name="keywords"
            content="integrated creative agency,digital marketing solutions,marketing communications,advertising agency,social media and communications,brand strategy,mobile development"
          />
          <meta
            property="og:title"
            content="Wunderfauks | Works and Portfolios"
          />
          <meta
            property="og:image"
            content="https://wunderfauks.com/wp-content/themes/wunderfauks/images/logo/logo-big.png"
          />
          <meta
            property="og:description"
            content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses."
          />
          <meta itemprop="name" content="Wunderfauks | Works and Portfolios" />
          <meta
            itemprop="description"
            content="An integrated creative agency focusing on new and innovative experiences. Design for a Purpose. Transform Brands. Grow Businesses."
          />
          <meta
            itemprop="image"
            content="https://wunderfauks.com/wp-content/themes/wunderfauks/images/logo/logo-big.png"
          />
        </Helmet>
        <Route
          exact
          path={this.props.match.path}
          render={(props) => (
            <WorkDefault {...props} studios={this.props.studios} />
          )}
        />
        <Route
          path={`${this.props.match.path}/:id`}
          render={(props) => (
            <WorkInner {...props} studios={this.props.studios} />
          )}
        />
      </div>
    );
  }
}

export default Work;
