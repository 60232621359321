import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { fadeIn, fadeInRight, fadeInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import StudioContact from "./StudioContact";
import $ from "jquery";
import { TweenMax, TimelineMax } from "gsap";
import { Fade } from "react-reveal";
import Loading from "react-progress-bar-plus";

const styles = {
  fadeInRight: {
    animation: "x 1s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInOnly: {
    animation: "x 2s ease",
    animationName: Radium.keyframes(fadeIn, "fadeIn"),
  },
  fadeInAbout1: {
    animation: "x 0.4s",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInAbout2: {
    animation: "x 0.8s",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInSocial: {
    animation: "x 0.8s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInSocial1: {
    animation: "x 0.6s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInSocial2: {
    animation: "x 1.5s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeInSocial3: {
    animation: "x 2.4s ease",
    animationName: Radium.keyframes(fadeInRight, "fadeInRight"),
  },
  fadeIns: {
    animation: "x 0.9s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp0: {
    animation: "x 0.8s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp1: {
    animation: "x 1s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp12: {
    animation: "x 1.3s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp2: {
    animation: "x 1.6s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp3: {
    animation: "x 1.9s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp4: {
    animation: "x 2.1s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  fadeInUp5: {
    animation: "x 2.4s ease",
    animationName: Radium.keyframes(fadeInUp, "fadeInUp"),
  },
  menu: {
    bmBurgerButton: {
      position: "fixed",
      width: "25px",
      height: "25px",
      right: "70px",
      top: "40px",
      zIndex: "3",
    },
    bmBurgerBars: {
      background: "#FFFFFF",
      height: "13%",
    },
    bmCrossButton: {
      height: "30px",
      width: "30px",
      right: "70px",
      top: "40px",
    },
    bmCross: {
      background: "#bdc3c7",
      height: "25px",
      width: "2px",
    },
    bmMenu: {
      background: "#1a1a1a",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
      overflow: "hidden",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em",
      paddingTop: "0",
      paddingRight: "40px",
      paddingLeft: "40px",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  },
};
class MenuHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactOpen: false,
      menuOpen: false,
      menuOn: false,
      originalUrl: "",
      showLoading: false,
    };
    this.closeMenu = this.closeMenu.bind(this);
    this.contact = this.contact.bind(this);
  }
  renderStudios() {
    const studios = this.props.studios;
    if (this.state.menuOn) {
      return (
        studios &&
        studios.map((studio, i) => {
          return (
            <Col md={6} key={i}>
              <StudioContact
                location={"menu"}
                key={studio.id}
                studio={studio}
              />
            </Col>
          );
        })
      );
    }
  }

  closeMenu() {
    window.history.pushState(
      { html: "", pageTitle: "" },
      "",
      this.state.originalUrl
    );
    this.setState({
      menuOn: false,
      showLoading: true,
    });
    setTimeout(() => {
      this.setState({ showLoading: false });
    }, 500);
    this.handleMenuClick();
  }
  contact(event) {
    event.preventDefault();

    if (window.location.pathname !== "/contact") {
      this.setState({
        originalUrl: window.location.pathname,
      });
    }
    this.setState({
      contactOpen: true,
    });
  }
  handleAbout = (event) => {
    event.preventDefault();

    window.history.pushState(
      { html: "", pageTitle: "" },
      "",
      this.state.originalUrl
    );

    this.setState({
      contactOpen: false,
    });
  };

  renderInnerMenuContent() {
    const { menuOn, contactOpen, originalUrl } = this.state;

    if (contactOpen) {
      window.history.pushState(
        { html: "contacts", pageTitle: "Wunderfauks | Contact Us" },
        "",
        "/contact"
      );
      return (
        <StyleRoot>
          <div className={"menu-contact"} style={{ marginTop: "20px" }}>
            <h5 style={styles.fadeInAbout1}>Contact</h5>
            <h1 className={"menu-discuss"} style={styles.fadeInAbout1}>
              Interested? Lets Discuss.
            </h1>
            <Container>
              <Row>
                <StyleRoot>
                  <Col
                    md={10}
                    xs={12}
                    className={"locations"} /* style={styles.fadeInAbout2} */
                  >
                    <Row className={"studios"}>{this.renderStudios()}</Row>
                  </Col>
                </StyleRoot>
              </Row>
            </Container>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <div className={"about"}>
          <h5 style={styles.fadeInAbout1}>About</h5>
          <p style={styles.fadeInAbout1}>
            Wunderfauks is an integrated creative agency focusing on new and
            innovative experiences. From the likes of creative expression to a
            campaign execution, communication and creative implementation,
            Wunderfauks provides tailored bulls-eye solutions that focus on
            results over activities.
          </p>
          <p style={styles.fadeInAbout2}>
            With digital as our strong suit, we have a dynamic team comprising
            of multi-disciplinary individuals with their own think tanks of
            interesting ideas and concepts to suit any need, logic and
            aspiration.
          </p>
        </div>
      );
    }
  }

  renderLinks() {
    const { contactOpen } = this.state;
    let windowWidth = window.innerWidth;
    if (windowWidth > 767) {
      return (
        <StyleRoot>
          <div style={styles.fadeIns}>
            <div style={styles.fadeInUp1}>
              <Link to="/work" onClick={this.closeMenu}>
                Work
              </Link>
            </div>
            <div style={styles.fadeInUp12}>
              <Link to="/services" onClick={this.closeMenu}>
                Services
              </Link>
            </div>
            <div style={styles.fadeInUp2}>
              <Link to="/services#approach" onClick={this.closeMenu}>
                Approach
              </Link>
            </div>
            <div style={styles.fadeInUp3}>
              <Link to="/careers" onClick={this.closeMenu}>
                Careers
              </Link>
            </div>
            <div style={styles.fadeInUp4}>
              {!contactOpen ? (
                <a href="#" className={"contact"} onClick={this.contact}>
                  Contact
                </a>
              ) : (
                <a href="#" className={"contact"} onClick={this.handleAbout}>
                  About
                </a>
              )}
            </div>
            <div style={styles.fadeInUp5}>
              <Link to="/sme-initiative" onClick={this.closeMenu}>
                SME Initiative
              </Link>
            </div>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <StyleRoot>
          <div style={styles.fadeIns}>
            <div style={styles.fadeInUp0}>
              <Link to="/about" onClick={this.closeMenu}>
                About
              </Link>
            </div>
            <div style={styles.fadeInUp1}>
              <Link to="/work" onClick={this.closeMenu}>
                Work
              </Link>
            </div>
            <div style={styles.fadeInUp12}>
              <Link to="/services" onClick={this.closeMenu}>
                Services
              </Link>
            </div>
            <div style={styles.fadeInUp2}>
              <Link to="/services#approach" onClick={this.closeMenu}>
                Approach
              </Link>
            </div>
            <div style={styles.fadeInUp3}>
              <Link to="/careers" onClick={this.closeMenu}>
                Careers
              </Link>
            </div>
            <div style={styles.fadeInUp4}>
              <Link
                to="/about#contact"
                className={"contact"}
                onClick={this.closeMenu}
              >
                Contact
              </Link>
            </div>
            <div style={styles.fadeInUp3}>
              <Link to="/sme-initiative" onClick={this.closeMenu}>
                SME Initiative
              </Link>
            </div>
          </div>
        </StyleRoot>
      );
    }
  }
  renderLinkContact() {
    if (!this.state.contactOpen) {
      return (
        <StyleRoot>
          <div style={styles.fadeInUp4}>
            <a href="/#" className={"contact"} onClick={this.contact}>
              Contact
            </a>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <a href="/#" className={"contact"} onClick={this.contact}>
          Contact
        </a>
      );
    }
  }
  renderSocial() {
    if (!this.state.contactOpen) {
      return (
        <StyleRoot>
          <div>
            <Row className={"menu-soc-med"}>
              <Col md={{ span: 2 }} className="col-md-offset-6 col-xs-4">
                <div style={styles.fadeInSocial1}>
                  <a
                    href="https://www.facebook.com/Wunderfauks/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="facebook" />
                  </a>
                </div>
              </Col>

              <Col md={2} className="col-xs-4">
                <div style={styles.fadeInSocial2}>
                  <a
                    href="https://www.instagram.com/wunderfauks/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="instagram" />
                  </a>
                </div>
              </Col>

              <Col md={2} className="col-xs-4">
                <div style={styles.fadeInSocial3}>
                  <a
                    href="https://sg.linkedin.com/company/wunderfauks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="linkedin" />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </StyleRoot>
      );
    } else {
      return (
        <StyleRoot>
          <div>
            <Row className={"menu-soc-med"}>
              <Col md={{ span: 2 }} xs={4} className="col-md-offset-6">
                <div style={styles.fadeInSocial1}>
                  <a
                    href="https://www.facebook.com/Wunderfauks/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="facebook" />
                  </a>
                </div>
              </Col>

              <Col md={2} xs={4}>
                <div style={styles.fadeInSocial2}>
                  <a
                    href="https://www.instagram.com/wunderfauks/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="instagram" />
                  </a>
                </div>
              </Col>

              <Col md={2} xs={4}>
                <div style={styles.fadeInSocial3}>
                  <a
                    href="https://sg.linkedin.com/company/wunderfauks"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="linkedin" />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </StyleRoot>
      );
    }
  }

  handleMenuClick = () => {
    const { menuOn } = this.state;
    if (menuOn) {
      this.setState({
        menuOn: false,
        contactOpen: false,
      });

      window.history.pushState(
        { html: "", pageTitle: "" },
        "",
        this.state.originalUrl
      );
      $("#menu-items").fadeOut(100);
      $("#logo-link").delay(100).fadeIn(100);
      if (window.innerWidth < 767) {
        TweenMax.to($("#menu-open"), 0.3, {
          width: "0px",
          height: "0px",
          right: "40px",
          top: "32px",
          ease: "Power4.easeInOut",
        });
      } else {
        TweenMax.to($("#menu-open"), 0.3, {
          width: "0px",
          height: "0px",
          right: "85px",
          top: "50px",
          ease: "Power4.easeInOut",
        });
      }
      TweenMax.to($("#menu-line-1"), 0.1, {
        rotation: 0,
        top: "20px",
      });
      TweenMax.to($("#menu-line-2"), 0.1, {
        rotation: 0,
        top: "30px",
      });
      $("#menu-line-1").removeClass("menu-line-1a");
      $("body").css({ overflow: "auto" });
    } else {
      // open menu
      $("body").css({ overflow: "hidden" });
      this.setState({
        menuOn: true,
      });

      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      let length = 0;
      if (windowWidth > windowHeight) {
        length = windowWidth;
      } else {
        length = windowHeight;
      }

      length = length * 2.5;
      let center = length / 2 + "px";

      TweenMax.to($("#menu-open"), 0.6, {
        width: length + "px",
        height: length + "px",
        right: "-=" + center,
        top: "-=" + center,
        ease: "Power4.easeInOut",
      });
      $("#menu-items").delay(200).fadeIn(200);
      $("#logo-link").delay(200).fadeOut(100);

      $("#menu-line-1").addClass("menu-line-1a");
      TweenMax.to($("#menu-line-1"), 0.01, {
        rotation: 45,
        top: "25px",
      });
      TweenMax.to($("#menu-line-2"), 0.01, {
        rotation: -45,
        top: "25px",
      });
    }
  };

  render() {
    const { showLoading } = this.state;

    return (
      <StyleRoot>
        {showLoading && (
          <div className={"loader"}>
            <Loading
              percent={0}
              spinner={false}
              autoIncrement={true}
              intervalTime={1}
            />
          </div>
        )}
        <div id="menu-div">
          <div id="menu-button" onClick={() => this.handleMenuClick()}>
            <div id="menu-line-1" />
            <div id="menu-line-2" />
          </div>
        </div>
        <div id="menu-open"></div>
        <div id="menu-items">
          <Container fluid={true} className="menu-contact-div">
            {this.renderInnerMenuContent()}
          </Container>
          <div className="TopBar">{this.renderLinks()}</div>
          {this.renderSocial()}
          <div id="menu-footer">
            <p style={{ float: "left" }}>&copy; {new Date().getFullYear()}</p>
            <p style={{ float: "right" }}>An Integrated Creative Agency.</p>
          </div>
        </div>
      </StyleRoot>
    );
  }
}

export default MenuHead;
