import React, { Component } from 'react';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import StudioClock from './StudioClock';
import StudioClockMenu from './StudioClockMenu';
import Radium, { StyleRoot } from 'radium';
import { fadeIn, fadeInRight, fadeInUp } from 'react-animations';

import WhatsAppIcon from '../assets/whatsapp.svg'

const styles = {
   fadeInAbout2: {
      animation: 'x 0.5s',
      animationName: Radium.keyframes(fadeInUp, 'fadeInUp'),
   },
}

export default class StudioContact extends Component {

   constructor(props) {
      super(props);
      let date = moment();
      this.state = {
         date: date,
         menu: props.location,
      }
   }
   renderClock() {
      if (this.state.menu === 'menu') {
         return (
            <StudioClockMenu country={this.props.studio} />
         )
      }
      if (this.state.menu === 'footer') {
         return (
            <StudioClock country={this.props.studio} />
         )
      }
   }

   render() {
      const { studio, key } = this.props;
      return (
         <StyleRoot>
            <div>
               <Row key={key} className={'studio'}   >
                  <Col md={5} xs={5} className='clocks' >
                     { this.renderClock() }
                  </Col>
                  <Col md={7} xs={7} className="address-div">
                     <div className={'studio-details'}>
                        <h1 className={'studio-title'} onClick={this.onClick}><span style={{display: "inline-block" }}>/ </span>{studio.name}</h1>
                        <div className={'vcard'}>
                           <a href={`tel:${studio.phone}`} style={{ display: 'block', marginBottom: 0 }} >{studio.phone}</a>
                           <a href="tel:+6582011579" >+65 8201 1579 / </a>
                           <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=6582011579&text=Hi there, I would like to enquire/engage Wunderfauks's services." style={{lineHeight: 1.8}}>
                           <img src={WhatsAppIcon} width="15px" style={{ verticalAlign: 'middle', marginRight: 5, marginTop: -4  }} />
                           WhatsApp
                        </a>
                        <div className={'email'}><a href={'mailto:'+studio.email}>{studio.email}</a></div>
                        <a className={'adr'}  rel="noreferrer" href={studio.address.map} target="_blank" >
                           <div className={'street-address'}>
                              {studio.address['street-address']}
                           </div>
                           <div className={'locality'}>{studio.address['locality']}</div>
                           <div className={'region'}>{studio.address['region']}</div>
                           <div className={'postal-code'}>{studio.address['postal-code']}</div>
                        </a>
                     </div>
                  </div>
               </Col>
            </Row>
         </div>
      </StyleRoot>

   )
}
}
