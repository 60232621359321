import React, { Component } from 'react';

export default class OverlappingCirc extends Component {

  constructor(props) {
    super(props);
    this.state = {
      radius: 265,
      displayOverlap: false,
      request: 0,
    }
    this.animateCircles = this.animateCircles.bind(this);
  }
  componentDidMount() {
    const canvas = document.getElementById('overlappingCirc');
    if(!canvas){
      return;
    }
    const context = canvas.getContext('2d');
    const centerleftX = canvas.width / 4 - 40;
    const centerRightX = 3 * canvas.width / 4 + 40;
    const centerY = canvas.height / 2;
    let circles = [[centerleftX + 100, centerY], [centerRightX-100,centerY]];

    for(let i = 0; i < circles.length; i++) {
      context.lineWidth = .2;
      context.beginPath();
      context.arc(circles[i][0], circles[i][1], this.state.radius, 0, Math.PI * 2, true);
      context.strokeStyle = "#1A1A1A";
      context.stroke();
    }
    window.addEventListener('scroll', this.animateCircles);
    this.renderAnimCircle();
  }
  animateCircles() {
    let circlesContainer = document.getElementById('circlesContainer');
    if (circlesContainer) {
      let distance = circlesContainer.getBoundingClientRect().top;
      if (distance <= 350) {
        this.setState({
          displayOverlap: true,
        })
      }
    }
  }
  renderAnimCircle(){
    const leftSemi = document.getElementById('leftSemi');
    const leftSemiCtx = leftSemi.getContext('2d');

    const rightSemi = document.getElementById('rightSemi');
    const rightSemiCtx = rightSemi.getContext('2d');

    const endPercent = 50;
    let curPerc = 0;
    const centerleftX = leftSemi.width / 4 - 40;
    const circ = Math.PI * 2;
    const quart = Math.PI / 2;
    const centerY = leftSemi.height / 2;

    let animate = (current) => {
      leftSemiCtx.lineWidth = 3;
      leftSemiCtx.clearRect(0, 0, leftSemi.width, leftSemi.height);
      leftSemiCtx.beginPath();
      leftSemiCtx.arc(centerleftX + 550, centerY, this.state.radius, -(quart), (circ * current) - quart, false);
      leftSemiCtx.strokeStyle = '#1A1A1A';
      leftSemiCtx.stroke();

      rightSemiCtx.lineWidth = 3;
      rightSemiCtx.clearRect(0, 0, leftSemi.width, leftSemi.height);
      rightSemiCtx.beginPath();
      rightSemiCtx.arc(centerleftX + 550, centerY, this.state.radius, -(quart), (circ * current) - quart, false);
      rightSemiCtx.strokeStyle = '#1A1A1A';
      rightSemiCtx.stroke();
      curPerc += .4;

      if (curPerc <= endPercent) {
        this.setState({
          request: requestAnimationFrame(() => {
            animate(curPerc/100);
          }),
        })
      }
    }
    animate();
  }
  componentWillUnmount() {
    cancelAnimationFrame(this.state.request);
  }

  render() {
    return (
      <div id={'circlesContainer'}>
        <canvas id={'rightSemi'} width={1140} height={600} />
        <canvas id={'leftSemi'} width={1140} height={600} />
        <canvas id={'overlappingCirc'} width={1140} height={600} />
      </div>
    )
  }
}
