import React, {Component} from 'react'

export default class ShreddedText extends Component {

  render() {
    return (
      <svg x="0px" y="0px" viewBox="0 0 1900 600"  overflow="visible">


        <g>
        	<g>
        		<path d="M164.788,29.42l8.086,13.229l6.484-13.201
        			c-0.271-0.004-0.524-0.028-0.798-0.028H164.788z"/>
        		<path d="M179.358,29.449l-6.484,13.201l30.376,49.688l18.379-24.79
        			C219.866,42.889,205.046,29.722,179.358,29.449z"/>
        		<path d="M203.25,92.338l10.742,17.574h3.012
        			c3.004,0,4.805-1.802,4.805-4.805V72.67c0-1.76-0.062-3.466-0.18-5.122L203.25,92.338z"/>
        		<path d="M159.902,69.065h10.249c7.809,0,12.014,4.205,12.014,12.014
        			v24.028c0,3.003,1.501,4.805,4.805,4.805h3.25l13.031-17.574l-30.376-49.688L159.902,69.065z"/>
        		<polygon points="190.219,109.912 213.992,109.912 203.25,92.338 		"/>
        		<path d="M124.446,49.2l14.459-19.502
        			c-8.827,0.753-16.186,3.172-21.978,7.204L124.446,49.2z"/>
        		<path d="M144.922,29.42c-2.086,0-4.074,0.112-6.016,0.277
        			L124.446,49.2l18.211,29.792c0.672-6.448,4.532-9.927,11.875-9.927h5.37l12.972-26.416l-8.086-13.229H144.922z"/>
        		<path d="M101.372,72.67v7.651L124.446,49.2l-7.519-12.298
        			C106.749,43.988,101.372,56.006,101.372,72.67z"/>
        		<path d="M101.372,80.321v16.53l24.89,40.717l16.257-33.105V81.079
        			c0-0.739,0.068-1.413,0.139-2.087L124.446,49.2L101.372,80.321z"/>
        		<polygon points="142.519,164.161 142.519,104.462 126.262,137.568
        			"/>
        		<path d="M217.004,189.203h-6.974l11.779,19.268v-14.463
        			C221.809,191.003,220.008,189.203,217.004,189.203z"/>
        		<path d="M186.97,189.203c-3.304,0-4.805,1.801-4.805,4.805v24.028
        			c0,3.241-0.701,5.781-2.007,7.696l23.379,38.245c11.922-6.752,18.272-19.398,18.272-37.531V208.47l-11.779-19.268H186.97z"/>
        		<polygon points="101.372,188.25 126.262,137.568 101.372,96.851 		"/>
        		<path d="M101.372,188.25v38.195c0,1.082,0.065,2.106,0.109,3.15
        			l41.038-55.349v-10.085l-16.257-26.593L101.372,188.25z"/>
        		<path d="M170.15,230.049h-15.618c-7.808,0-12.013-3.905-12.013-12.014
        			v-43.789l-41.038,55.349c1.126,26.173,16.29,40.099,43.44,40.099h33.638c9.992,0,18.338-1.956,24.977-5.717l-23.379-38.245
        			C178.197,228.612,174.838,230.049,170.15,230.049z"/>
        		<polygon points="251.833,261.604 253.356,260.077 251.833,257.584
        			"/>
        		<path d="M251.833,261.604v3.285c0,3.002,1.501,4.805,5.105,4.805
        			h2.296l-5.878-9.617L251.833,261.604z"/>
        		<path d="M287.874,29.42h-30.936c-0.291,0-0.546,0.031-0.811,0.053
        			l36.551,59.793V34.227C292.679,31.223,290.878,29.42,287.874,29.42z"/>
        		<polygon points="371.97,71.195 344.344,54.774 371.97,99.965 		"/>
        		<path d="M371.97,34.227c0-3.004-1.501-4.806-5.107-4.806h-30.934
        			c-2.667,0-4.38,1.426-4.731,3.847l13.146,21.507l27.625,16.421V34.227z"/>
        		<path d="M331.198,33.267c-0.044,0.306-0.075,0.623-0.075,0.959v12.688
        			l13.222,7.859L331.198,33.267z"/>
        		<polygon points="331.123,46.915 331.123,129.734 317.417,129.734
        			342.443,170.673 371.97,141.04 371.97,99.965 344.344,54.774 		"/>
        		<polygon points="353.949,189.496 371.97,165.192 371.97,141.04
        			342.443,170.673 		"/>
        		<polygon points="371.97,218.975 371.97,165.192 353.949,189.496 		"/>
        		<path d="M331.123,220.284v44.605c0,3.002,1.802,4.805,4.806,4.805
        			h28.55l7.491-10.104v-40.615l-18.021-29.479L331.123,220.284z"/>
        		<path d="M366.863,269.694c3.606,0,5.107-1.802,5.107-4.805v-5.299
        			l-7.491,10.104H366.863z"/>
        		<polygon points="292.679,238.629 292.679,220.612 285.853,227.463
        			"/>
        		<polygon points="331.123,182.032 331.123,220.284 353.949,189.496
        			342.443,170.673 		"/>
        		<path d="M292.679,129.734V89.267l-36.551-59.793
        			c-3.003,0.265-4.295,1.992-4.295,4.753v137.586l34.02,55.65l6.826-6.851v-51.233h38.444v12.653l11.321-11.359l-25.026-40.938
        			H292.679z"/>
        		<path d="M253.356,260.077l5.878,9.617h28.639
        			c3.004,0,4.805-1.802,4.805-4.805v-26.26l-6.826-11.166L253.356,260.077z"/>
        		<polygon points="251.833,257.584 253.356,260.077 285.853,227.463
        			251.833,171.813 		"/>
        		<path d="M393.288,264.588c-0.9,3.304,0.602,5.106,3.905,5.106h5.783
        			l-7.974-13.044L393.288,264.588z"/>
        		<polygon points="403.567,216.974 425.454,187.455 413.988,168.699
        			"/>
        		<path d="M403.567,216.974l-8.565,39.676l7.974,13.044h26.354
        			c2.703,0,4.204-1.502,4.805-4.205l8.109-43.85h4.107l-20.897-34.184L403.567,216.974z"/>
        		<polygon points="472.636,29.42 460.465,29.42 465.964,38.418 		"/>
        		<polygon points="483.194,29.42 472.636,29.42 465.964,38.418
        			469.107,43.559 		"/>
        		<path d="M505.116,80.01l-10.012-46.385
        			c-0.601-3.004-2.402-4.205-5.105-4.205h-6.805l-14.087,14.138l28.486,46.6L505.116,80.01z"/>
        		<polygon points="505.116,80.01 497.594,90.159 512.611,114.726 		"/>
        		<polygon points="465.964,38.418 441.939,70.822 469.107,43.559 		"/>
        		<polygon points="461.224,124.249 457.938,122.297 460.741,126.88
        			"/>
        		<polygon points="468.975,81.98 475.852,119.482 497.594,90.159
        			469.107,43.559 441.939,70.822 433.462,82.256 457.938,122.297 461.224,124.249 		"/>
        		<polygon points="504.909,199.132 541.72,259.349 543.354,257.143
        			524.986,172.054 		"/>
        		<path d="M543.354,257.143l-1.634,2.207l3.25,5.318
        			c-0.004-0.027-0.004-0.051-0.009-0.08L543.354,257.143z"/>
        		<polygon points="512.611,114.726 497.594,90.159 475.852,119.482
        			478.623,134.59 522.534,160.692 		"/>
        		<polygon points="478.623,134.59 484.266,165.363 504.909,199.132
        			524.986,172.054 522.534,160.692 		"/>
        		<path d="M465.964,38.418l-5.5-8.998h-12.214
        			c-2.703,0-4.505,1.201-5.105,4.205l-9.916,45.938l8.709-8.741L465.964,38.418z"/>
        		<polygon points="441.939,70.822 433.23,79.563 432.861,81.272
        			433.462,82.256 		"/>
        		<polygon points="432.861,81.272 432.314,83.803 433.462,82.256 		"/>
        		<polygon points="433.462,82.256 432.314,83.803 427.864,104.42
        			457.938,122.297 		"/>
        		<polygon points="427.864,104.42 413.988,168.699 425.454,187.455
        			457.578,144.128 460.741,126.88 457.938,122.297 		"/>
        		<polygon points="488.197,186.799 449.753,186.799 457.578,144.128
        			425.454,187.455 446.351,221.639 488.221,221.639 504.909,199.132 484.266,165.363 		"/>
        		<path d="M541.72,259.349l-7.671,10.345h6.708
        			c3.573,0,4.78-1.777,4.214-5.026L541.72,259.349z"/>
        		<path d="M488.221,221.639h7.484l8.409,43.85
        			c0.602,2.703,2.104,4.205,4.806,4.205h25.129l7.671-10.345l-36.811-60.217L488.221,221.639z"/>
        		<polygon points="697.226,49.612 690.436,58.771 697.226,69.878 		"/>
        		<path d="M697.226,49.612V34.227c0-3.004-1.802-4.806-4.807-4.806
        			h-19.926l17.942,29.35L697.226,49.612z"/>
        		<path d="M672.493,29.42h-11.309c-3.305,0-4.806,1.802-4.806,4.806
        			v70.478l34.057-45.934L672.493,29.42z"/>
        		<polygon points="697.226,69.878 690.436,58.771 656.379,104.704
        			656.379,118.282 697.226,185.103 		"/>
        		<path d="M615.082,50.538l-6.157-17.213
        			c-0.9-2.703-2.703-3.904-5.405-3.904h-1.463l12.963,21.202L615.082,50.538z"/>
        		<polygon points="615.082,50.538 615.02,50.623 615.245,50.993 		"/>
        		<polygon points="602.057,29.42 600.039,29.42 596.26,75.924
        			615.02,50.623 		"/>
        		<polygon points="609.826,167.492 602.334,177.594 609.826,189.849
        			"/>
        		<polygon points="656.525,240.337 645.896,234.02 650.886,247.943
        			"/>
        		<polygon points="697.226,185.442 697.226,185.103 656.379,118.282
        			656.379,160.97 654.577,160.97 641.588,124.652 618.072,156.369 645.896,234.02 656.525,240.337 		"/>
        		<polygon points="697.226,243.131 697.226,185.442 656.525,240.337
        			686.213,257.984 		"/>
        		<path d="M656.525,240.337l-5.639,7.606l6.395,17.846
        			c0.901,2.703,2.703,3.904,5.406,3.904h14.846l8.682-11.709L656.525,240.337z"/>
        		<path d="M686.213,257.984l-8.682,11.709h14.888
        			c3.004,0,4.807-1.802,4.807-4.805v-0.358L686.213,257.984z"/>
        		<polygon points="697.226,243.131 686.213,257.984 697.226,264.531
        			"/>
        		<polygon points="609.826,167.492 609.826,137.542 611.328,137.542
        			618.072,156.369 641.588,124.652 615.245,50.993 615.02,50.623 596.26,75.924 589.679,156.891 602.334,177.594 		"/>
        		<path d="M600.039,29.42h-26.253c-3.003,0-4.806,1.802-4.806,4.806
        			v78.491l27.28-36.793L600.039,29.42z"/>
        		<polygon points="596.26,75.924 568.98,112.718 568.98,123.032
        			589.679,156.891 		"/>
        		<polygon points="568.98,188.3 568.98,206.935 574.223,215.511
        			586.231,199.315 586.289,198.589 		"/>
        		<polygon points="589.679,156.891 568.98,123.032 568.98,188.3
        			586.289,198.589 		"/>
        		<polygon points="583.659,230.948 586.231,199.315 574.223,215.511
        			"/>
        		<polygon points="586.623,198.787 586.289,198.589 586.231,199.315
        			"/>
        		<polygon points="602.334,177.594 589.679,156.891 586.289,198.589
        			586.623,198.787 		"/>
        		<polygon points="609.826,189.849 602.334,177.594 586.623,198.787
        			609.826,212.58 		"/>
        		<path d="M586.623,198.787l-0.392,0.529l-2.573,31.632l23.488,38.422
        			c1.829-0.645,2.679-2.223,2.679-4.481V212.58L586.623,198.787z"/>
        		<path d="M580.51,269.694h24.511c0.818,0,1.525-0.112,2.126-0.324
        			l-23.488-38.422L580.51,269.694z"/>
        		<polygon points="568.98,222.582 574.223,215.511 568.98,206.935 		"/>
        		<path d="M568.98,222.582v42.308c0,3.002,1.802,4.805,4.806,4.805
        			h6.724l3.149-38.746l-9.436-15.437L568.98,222.582z"/>
        		<path d="M853.393,146.741v-3.792c0-3.303-1.804-4.805-4.807-4.805
        			h-5.204l7.404,12.112L853.393,146.741z"/>
        		<polygon points="853.393,146.741 850.787,150.256 853.393,154.521
        			"/>
        		<polygon points="793.181,56.024 783.513,69.065 801.154,69.065 		"/>
        		<polygon points="768.094,147.549 768.094,89.86 735.946,133.219
        			755.275,164.838 		"/>
        		<polygon points="768.094,147.549 755.275,164.838 768.094,185.808
        			"/>
        		<path d="M768.094,89.86v-8.781c0-7.809,3.905-12.014,12.015-12.014
        			h3.403l9.668-13.041L776.918,29.42h-6.42c-28.233,0-43.25,15.017-43.25,43.25v46.32l8.697,14.229L768.094,89.86z"/>
        		<path d="M853.393,219.173v-64.652l-2.606-4.264l-37.04,49.957v17.822
        			c0,8.109-4.205,12.014-12.013,12.014h-6.595l22.682,37.103L853.393,219.173z"/>
        		<path d="M853.393,226.445v-7.272l-35.572,47.979l1.193,1.953
        			C841.284,266.14,853.393,251.519,853.393,226.445z"/>
        		<path d="M798.129,138.144c-3.303,0-4.806,1.502-4.806,4.805v18.627
        			l17.373-23.432H798.129z"/>
        		<path d="M798.129,177.789h15.618v22.424l37.04-49.957l-7.404-12.112
        			h-32.687l-17.373,23.432v11.407C793.323,175.986,794.826,177.789,798.129,177.789z"/>
        		<polygon points="727.249,118.99 727.249,144.95 735.946,133.219 		"/>
        		<polygon points="735.946,133.219 727.249,144.95 727.249,202.639
        			755.275,164.838 		"/>
        		<path d="M762.568,269.238l29.058-39.189h-11.517
        			c-7.81,0-12.015-3.905-12.015-12.014v-22.433l-35.324,47.643l12.7,20.775C750.264,266.722,755.973,268.471,762.568,269.238z"/>
        		<path d="M732.771,243.246l35.324-47.643v-9.794l-12.819-20.97
        			l-28.026,37.8v23.807c0,3.062,0.21,5.938,0.562,8.687L732.771,243.246z"/>
        		<path d="M810.143,269.694c2.092,0,4.075-0.126,6.015-0.302l1.663-2.24
        			l-22.682-37.103h-3.513l-29.058,39.189c2.517,0.292,5.151,0.456,7.93,0.456H810.143z"/>
        		<path d="M817.821,267.151l-1.663,2.24
        			c0.96-0.086,1.929-0.164,2.855-0.288L817.821,267.151z"/>
        		<path d="M732.771,243.246l-4.96-8.113
        			c0.519,4.065,1.466,7.74,2.74,11.107L732.771,243.246z"/>
        		<path d="M745.47,264.021l-12.7-20.775l-2.221,2.994
        			C733.528,254.108,738.503,260.095,745.47,264.021z"/>
        		<path d="M832.521,108.71l20.872-28.153V72.67
        			c0-12.151-2.862-21.83-8.329-28.933l-31.317,42.237v3.692l11.642,19.043H832.521z"/>
        		<path d="M853.393,103.906V80.558l-20.872,28.153h16.065
        			C851.589,108.71,853.393,106.909,853.393,103.906z"/>
        		<path d="M801.734,69.065c7.808,0,12.013,4.205,12.013,12.014v4.896
        			l31.317-42.237c-6.816-8.852-17.689-13.692-32.225-14.224l-19.657,26.511l7.973,13.041H801.734z"/>
        		<path d="M812.838,29.514c-0.896-0.033-1.77-0.093-2.695-0.093h-33.225
        			l16.263,26.604L812.838,29.514z"/>
        		<path d="M818.553,108.71h6.835l-11.642-19.043v14.239
        			C813.747,106.909,815.249,108.71,818.553,108.71z"/>
        		<polygon points="940.379,29.42 933.371,29.42 936.538,34.602 		"/>
        		<path d="M1003.25,64.261V34.227c0-3.004-1.202-4.806-4.806-4.806
        			h-4.363l-29.615,39.946h33.979C1002.048,69.366,1003.25,67.263,1003.25,64.261z"/>
        		<polygon points="964.466,69.366 994.081,29.42 940.379,29.42
        			936.538,34.602 957.79,69.366 		"/>
        		<polygon points="936.538,34.602 933.371,29.42 891.308,29.42
        			883.414,40.067 883.414,44.208 918.618,58.771 		"/>
        		<polygon points="918.618,58.771 883.414,44.208 883.414,92.795
        			887.922,100.17 		"/>
        		<path d="M887.918,29.42c-2.702,0-4.504,1.802-4.504,4.806v5.841
        			l7.894-10.647H887.918z"/>
        		<polygon points="923.358,124.808 923.358,69.366 944.231,69.366
        			918.618,58.771 887.922,100.17 912.191,139.87 		"/>
        		<polygon points="957.79,69.366 936.538,34.602 918.618,58.771
        			944.231,69.366 		"/>
        		<path d="M964.796,169.379l20.732-27.962v-6.877
        			c0-3.303-1.801-5.105-4.805-5.105h-57.365v-4.627l-11.167,15.062l18.04,29.509H964.796z"/>
        		<path d="M985.528,164.274v-22.857l-20.732,27.962h15.928
        			C983.728,169.379,985.528,167.577,985.528,164.274z"/>
        		<path d="M998.411,269.694l4.839-6.527v-28.313
        			c0-3.303-1.202-4.805-4.806-4.805h-31.126l24.234,39.645H998.411z"/>
        		<path d="M1003.25,264.889v-1.723l-4.839,6.527h0.033
        			C1002.048,269.694,1003.25,267.892,1003.25,264.889z"/>
        		<polygon points="923.358,225.267 923.358,169.379 930.231,169.379
        			912.191,139.87 883.414,178.682 883.414,203.631 908.715,245.017 		"/>
        		<polygon points="887.922,100.17 883.414,106.25 883.414,178.682
        			912.191,139.87 		"/>
        		<polygon points="883.414,92.795 883.414,106.25 887.922,100.17 		"/>
        		<polygon points="991.553,269.694 967.318,230.049 923.358,230.049
        			923.358,225.267 908.715,245.017 923.799,269.694 		"/>
        		<path d="M887.918,269.694h2.501l18.295-24.677l-25.301-41.386v61.258
        			C883.414,267.892,885.216,269.694,887.918,269.694z"/>
        		<polygon points="908.715,245.017 890.419,269.694 923.799,269.694
        			"/>
        		<polygon points="1149.203,66.315 1128.019,94.886 1149.203,129.542
        			"/>
        		<path d="M1149.203,66.315V34.227c0-3.004-1.804-4.806-4.808-4.806
        			h-31.233c-3.305,0-4.806,1.802-4.806,4.806v28.498l19.662,32.162L1149.203,66.315z"/>
        		<polygon points="1108.356,62.724 1108.356,121.405 1128.019,94.886
        			"/>
        		<polygon points="1149.203,161.315 1119.975,200.737 1149.203,248.551
        					"/>
        		<polygon points="1149.203,129.542 1128.019,94.886 1108.356,121.405
        			1108.356,137.259 1149.203,154.155 		"/>
        		<polygon points="1149.203,161.315 1149.203,154.155 1108.356,137.259
        			1108.356,181.733 1119.975,200.737 		"/>
        		<path d="M1149.203,264.889v-12.333l-12.706,17.138h7.898
        			C1147.399,269.694,1149.203,267.892,1149.203,264.889z"/>
        		<path d="M1113.162,269.694h23.335l12.706-17.138v-4.004
        			l-29.229-47.814l-11.618,15.668v48.484C1108.356,267.892,1109.857,269.694,1113.162,269.694z"/>
        		<polygon points="1108.356,181.733 1108.356,216.405 1119.975,200.737
        					"/>
        		<path d="M1265.737,30.083l29.234,19.953
        			C1289.739,38.869,1279.88,32.116,1265.737,30.083z"/>
        		<polygon points="1246.995,29.42 1235.988,29.42 1240.962,37.558 		"/>
        		<path d="M1296.483,53.914c-0.462-1.331-0.938-2.652-1.512-3.878
        			l-29.234-19.953c-3.017-0.433-6.219-0.663-9.619-0.663h-9.123l-6.033,8.137l30.57,50.009L1296.483,53.914z"/>
        		<path d="M1294.561,109.312c3.303,0,4.806-1.803,4.806-4.807V72.67
        			c0-7.117-1.002-13.348-2.883-18.756l-24.951,33.652l13.294,21.746H1294.561z"/>
        		<path d="M1240.962,37.558l-4.974-8.137h-13.509
        			c-16.294,0-28.257,5.146-35.43,15.007l22.061,36.088L1240.962,37.558z"/>
        		<path d="M1220.075,81.079c0-7.809,4.505-12.014,12.013-12.014h14.417
        			c8.109,0,12.016,4.205,12.016,12.014v23.426c0,0.195,0.027,0.369,0.043,0.554l12.969-17.493l-30.57-50.009l-31.852,42.958
        			l10.965,17.937V81.079z"/>
        		<path d="M1263.323,109.312h21.503l-13.294-21.746l-12.969,17.493
        			C1258.778,107.721,1260.515,109.312,1263.323,109.312z"/>
        		<polygon points="1240.62,129.26 1238.479,128.557 1239.682,130.524
        			"/>
        		<path d="M1299.366,216.271v-59.206c0-4.345-1.576-7.291-4.58-9.381
        			l-24.452,32.981l19.308,31.585L1299.366,216.271z"/>
        		<path d="M1299.366,226.445v-10.174l-9.725-4.021l9.67,15.817
        			C1299.321,227.521,1299.366,227.001,1299.366,226.445z"/>
        		<path d="M1294.786,147.684c-0.939-0.654-2.011-1.229-3.229-1.731
        			l-50.938-16.693l-0.938,1.265l30.652,50.141L1294.786,147.684z"/>
        		<polygon points="1195.508,190.104 1186.224,190.104 1190.42,196.966
        					"/>
        		<polygon points="1258.521,196.6 1270.334,180.665 1239.682,130.524
        			1215.027,163.776 1258.521,178.09 		"/>
        		<path d="M1233.72,230.049h-1.632c-7.508,0-12.013-3.905-12.013-12.014
        			v-23.127c0-3.304-1.502-4.805-4.807-4.805h-19.761l-5.088,6.863l30.651,50.141L1233.72,230.049z"/>
        		<polygon points="1270.334,180.665 1258.521,196.6 1258.521,199.375
        			1289.642,212.25 		"/>
        		<path d="M1256.118,269.694c13.811,0,24.448-3.605,31.715-10.592
        			l6.3-8.498c3.223-6.116,4.987-13.629,5.179-22.538l-9.67-15.817l-31.121-12.875v18.66c0,8.109-4.207,12.014-12.016,12.014h-12.785
        			l-12.648,17.058l13.808,22.587H1256.118z"/>
        		<path d="M1287.833,259.102c2.518-2.42,4.602-5.275,6.3-8.498
        			L1287.833,259.102z"/>
        		<path d="M1187.339,154.663l27.688,9.113l24.654-33.252l-1.202-1.967
        			l-18.404-6.031V98.453l-10.965-17.937l-29.881,40.302v22.732C1179.229,149.257,1181.934,152.861,1187.339,154.663z"/>
        		<path d="M1187.05,44.427c-5.123,7.044-7.82,16.478-7.82,28.243v48.147
        			l29.881-40.302L1187.05,44.427z"/>
        		<path d="M1186.224,190.104h-2.188c-3.005,0-4.806,1.501-4.806,4.805
        			v17.15l11.19-15.092L1186.224,190.104z"/>
        		<path d="M1205.955,267.494l15.116-20.387l-30.651-50.141
        			l-11.19,15.092v14.387C1179.229,248.472,1188.593,262.411,1205.955,267.494z"/>
        		<path d="M1234.879,269.694l-13.808-22.587l-15.116,20.387
        			c4.891,1.432,10.383,2.2,16.524,2.2H1234.879z"/>
        		<path d="M1516.691,30.211c-0.607-0.377-1.311-0.665-2.226-0.743
        			l1.253,2.051L1516.691,30.211z"/>
        		<path d="M1518.603,34.227c0-1.881-0.657-3.229-1.911-4.015
        			l-0.973,1.308l2.884,4.715V34.227z"/>
        		<polygon points="1458.127,29.42 1448.186,29.42 1452.679,36.77 		"/>
        		<path d="M1478.955,69.065h8.929l27.835-37.546l-1.253-2.051
        			c-0.22-0.019-0.429-0.048-0.668-0.048h-55.671l-5.448,7.35l26.276,42.987V69.065z"/>
        		<path d="M1518.603,64.261V36.234l-2.884-4.715l-27.835,37.546h25.914
        			C1517.104,69.065,1518.603,67.263,1518.603,64.261z"/>
        		<polygon points="1438.111,136.191 1478.955,81.105 1478.955,79.757
        			1452.679,36.77 1428.734,69.065 1438.111,69.065 		"/>
        		<polygon points="1478.955,81.105 1438.111,136.191 1438.111,147.728
        			1478.955,175.602 		"/>
        		<path d="M1403.271,69.065h25.464l23.944-32.295l-4.493-7.35h-44.915
        			c-3.002,0-4.806,1.802-4.806,4.806v30.034C1398.465,67.263,1400.269,69.065,1403.271,69.065z"/>
        		<polygon points="1478.955,182.66 1478.955,175.602 1438.111,147.728
        			1438.111,150.453 1467.358,198.299 		"/>
        		<polygon points="1478.955,182.66 1467.358,198.299 1478.955,217.267
        					"/>
        		<polygon points="1438.111,150.453 1438.111,237.748 1467.358,198.299
        					"/>
        		<polygon points="1472.312,269.694 1478.955,260.737 1478.955,217.267
        			1467.358,198.299 1438.111,237.748 1438.111,250.281 1449.979,269.694 		"/>
        		<path d="M1478.955,264.889v-4.152l-6.644,8.957h1.84
        			C1477.457,269.694,1478.955,268.192,1478.955,264.889z"/>
        		<path d="M1442.916,269.694h7.062l-11.867-19.413v14.608
        			C1438.111,268.192,1439.915,269.694,1442.916,269.694z"/>
        		<polygon points="1621.913,205.235 1621.913,216.252 1625.602,211.273
        					"/>
        		<path d="M1626.719,269.694h22.495l6.634-8.946l-30.246-49.475
        			l-3.688,4.979v48.637C1621.913,267.892,1623.713,269.694,1626.719,269.694z"/>
        		<path d="M1660.861,268.954l-5.014-8.206l-6.634,8.946h8.438
        			C1659.012,269.694,1660.072,269.436,1660.861,268.954z"/>
        		<polygon points="1542.621,75.524 1542.621,96.792 1549.744,87.183
        			"/>
        		<polygon points="1549.744,87.183 1542.621,96.792 1542.621,174.87
        			1575.904,129.977 		"/>
        		<polygon points="1583.47,194.214 1583.47,169.379 1599.994,169.379
        			1575.904,129.977 1542.621,174.87 1542.621,183.902 1564.534,219.75 		"/>
        		<polygon points="1583.47,246.932 1583.47,194.214 1564.534,219.75
        			1579.491,244.217 		"/>
        		<path d="M1650.462,29.42h-23.743c-3.006,0-4.806,1.802-4.806,4.806
        			v24.026l3.241,5.302L1650.462,29.42z"/>
        		<path d="M1662.757,87.275V34.227c0-3.004-1.503-4.806-5.104-4.806
        			h-7.19l-25.308,34.134l24.942,40.799L1662.757,87.275z"/>
        		<polygon points="1662.757,87.275 1650.097,104.354 1662.757,125.067
        					"/>
        		<polygon points="1621.913,58.253 1621.913,67.926 1625.154,63.555
        			"/>
        		<polygon points="1583.47,119.776 1583.47,41.7 1549.744,87.183
        			1575.904,129.977 		"/>
        		<polygon points="1601.879,169.379 1650.097,104.354 1625.154,63.555
        			1621.913,67.926 1621.913,129.734 1583.47,129.734 1583.47,119.776 1575.904,129.977 1599.994,169.379 		"/>
        		<polygon points="1621.913,205.235 1625.602,211.273 1662.757,161.165
        			1662.757,125.067 1650.097,104.354 1601.879,169.379 1621.913,169.379 		"/>
        		<polygon points="1662.757,251.43 1662.757,161.165 1625.602,211.273
        			1655.848,260.748 		"/>
        		<path d="M1662.757,264.889V251.43l-6.909,9.318l5.014,8.206
        			C1662.171,268.16,1662.757,266.757,1662.757,264.889z"/>
        		<path d="M1583.47,41.7v-7.474c0-3.004-1.803-4.806-4.811-4.806
        			h-30.934c-3.602,0-5.104,1.802-5.104,4.806v41.298l7.123,11.658L1583.47,41.7z"/>
        		<polygon points="1564.534,219.75 1542.621,183.902 1542.621,219.053
        			1557.515,229.218 		"/>
        		<polygon points="1564.534,219.75 1557.515,229.218 1579.491,244.217
        					"/>
        		<polygon points="1583.47,246.932 1579.491,244.217 1583.47,250.724
        			"/>
        		<polygon points="1542.621,219.053 1542.621,249.306 1557.515,229.218
        					"/>
        		<path d="M1547.726,269.694h30.934c3.008,0,4.811-1.802,4.811-4.805
        			v-14.165l-3.979-6.507l-21.977-14.999l-14.894,20.087v15.583C1542.621,267.892,1544.124,269.694,1547.726,269.694z"/>
        		<polygon points="1753.209,129.435 1733.54,129.435 1742.427,143.975
        					"/>
        		<path d="M1794.897,164.274v-12.441l-13.013,17.546h8.209
        			C1793.099,169.379,1794.897,167.577,1794.897,164.274z"/>
        		<path d="M1781.885,169.379l13.013-17.546V134.54
        			c0-3.303-1.799-5.105-4.804-5.105h-36.885l-10.782,14.54l15.529,25.405H1781.885z"/>
        		<path d="M1797.745,69.366l14.873-20.059V34.227
        			c0-3.004-1.201-4.806-4.804-4.806h-47.376l-8.051,10.857l17.783,29.089H1797.745z"/>
        		<path d="M1812.618,64.261V49.307l-14.873,20.059h10.069
        			C1811.417,69.366,1812.618,67.263,1812.618,64.261z"/>
        		<polygon points="1760.438,29.42 1745.749,29.42 1752.388,40.277 		"/>
        		<path d="M1697.288,29.42c-2.7,0-4.504,1.802-4.504,4.806v12.552
        			l12.871-17.358H1697.288z"/>
        		<polygon points="1752.388,40.277 1745.749,29.42 1705.655,29.42
        			1692.784,46.779 1692.784,62.763 1712.187,94.5 		"/>
        		<polygon points="1732.731,69.366 1770.171,69.366 1752.388,40.277
        			1712.187,94.5 1732.731,128.109 		"/>
        		<path d="M1812.618,234.854c0-3.303-1.201-4.805-4.804-4.805h-12.769
        			l17.572,28.745V234.854z"/>
        		<polygon points="1692.784,62.763 1692.784,120.667 1712.187,94.5
        			"/>
        		<polygon points="1742.427,143.975 1733.54,129.435 1732.731,129.435
        			1732.731,128.109 1712.187,94.5 1692.784,120.667 1692.784,174.186 1705.094,194.326 		"/>
        		<polygon points="1736.904,230.049 1732.731,230.049 1732.731,169.379
        			1757.956,169.379 1742.427,143.975 1705.094,194.326 1731.436,237.42 		"/>
        		<polygon points="1806.234,269.694 1812.618,261.087 1812.618,258.793
        			1795.046,230.049 1736.904,230.049 1731.436,237.42 1751.165,269.694 		"/>
        		<path d="M1812.618,264.889v-3.802l-6.384,8.606h1.58
        			C1811.417,269.694,1812.618,267.892,1812.618,264.889z"/>
        		<polygon points="1692.784,174.186 1692.784,210.933 1705.094,194.326
        					"/>
        		<polygon points="1731.436,237.42 1707.509,269.694 1751.165,269.694
        					"/>
        		<path d="M1697.288,269.694h10.221l23.927-32.273l-26.342-43.095
        			l-12.31,16.607v53.957C1692.784,267.892,1694.588,269.694,1697.288,269.694z"/>
        		<path d="M53.921,520.762c0,21.116,8.425,34.812,24.471,40.387
        			l6.581-8.877l-31.052-50.795V520.762z"/>
        		<path d="M78.393,561.148c4.071,1.416,8.607,2.332,13.649,2.685
        			l-7.068-11.562L78.393,561.148z"/>
        		<path d="M53.921,366.987v93.233l39.946-40.091v-32.546l-30.904-50.555
        			C57.009,344.223,53.921,354.244,53.921,366.987z"/>
        		<path d="M93.867,513.252V420.13l-39.946,40.091v41.256l31.052,50.795
        			l20.066-27.061C97.792,524.926,93.867,521.053,93.867,513.252z"/>
        		<polygon points="135.314,484.377 145.029,471.277 135.314,455.386
        			"/>
        		<path d="M135.314,484.377v28.875c0,8.111-4.205,12.015-12.013,12.015
        			H105.88c-0.297,0-0.554-0.046-0.841-0.056l-20.066,27.061l7.068,11.562c1.659,0.118,3.368,0.177,5.13,0.177h33.637
        			c6.441,0,12.16-0.818,17.205-2.363l25.761-34.744c0.169-1.982,0.284-4.01,0.284-6.141v-1.996l-29.03-47.488L135.314,484.377z"/>
        		<path d="M173.774,526.902l-25.761,34.744
        			C163.407,556.939,172.203,545.164,173.774,526.902z"/>
        		<path d="M147.508,325.954l15.286,25.006l6.742-6.767
        			C165.206,335.125,157.815,328.975,147.508,325.954z"/>
        		<path d="M162.794,350.96l11.264,18.428v-2.4
        			c0-8.964-1.545-16.562-4.522-22.794L162.794,350.96z"/>
        		<polygon points="174.059,518.766 174.059,432.122 145.029,471.277
        			"/>
        		<path d="M130.809,323.738H97.171c-15.49,0-26.973,4.549-34.208,13.291
        			l30.904,50.555v-13.087c0-7.812,4.205-12.015,12.013-12.015h17.421c7.808,0,12.013,4.203,12.013,12.015v4.04l27.48-27.577
        			l-15.286-25.006C142.585,324.512,137.039,323.738,130.809,323.738z"/>
        		<polygon points="135.314,378.537 135.314,455.386 145.029,471.277
        			174.059,432.122 174.059,369.388 162.794,350.96 		"/>
        		<path d="M240.188,323.932l6.06,9.912l-2.218-6.202
        			C243.318,325.509,242.023,324.342,240.188,323.932z"/>
        		<path d="M291.483,328.544v39.603l15.312-20.651l-13.878-22.702
        			C291.957,325.618,291.483,326.891,291.483,328.544z"/>
        		<polygon points="291.483,368.146 291.483,407.843 318,451.223
        			332.331,431.895 332.331,389.266 306.795,347.495 		"/>
        		<polygon points="332.331,474.661 332.331,431.895 318,451.223 		"/>
        		<path d="M292.917,324.793l13.878,22.702l17.613-23.757h-28.119
        			C294.804,323.738,293.702,324.12,292.917,324.793z"/>
        		<path d="M327.524,323.738h-3.116l-17.613,23.757l25.536,41.771
        			v-60.722C332.331,325.54,330.528,323.738,327.524,323.738z"/>
        		<polygon points="204.084,391.624 204.084,418.502 226.708,455.508
        			269.244,398.141 246.468,334.461 		"/>
        		<polygon points="291.483,455.288 289.682,455.288 269.244,398.141
        			226.708,455.508 244.93,485.32 244.93,431.859 246.432,431.859 274.428,509.991 318,451.223 291.483,407.843 		"/>
        		<path d="M327.524,564.01c3.004,0,4.807-1.797,4.807-4.803V541.76
        			l-16.499,22.25H327.524z"/>
        		<path d="M274.428,509.991l17.957,50.117
        			c0.901,2.7,2.704,3.901,5.407,3.901h18.041l16.499-22.25v-67.099L318,451.223L274.428,509.991z"/>
        		<path d="M240.188,323.932c-0.489-0.109-0.995-0.193-1.564-0.193
        			H208.89c-3.003,0-4.805,1.802-4.805,4.806v63.08l42.383-57.163l-0.221-0.617L240.188,323.932z"/>
        		<polygon points="204.084,486.022 226.708,455.508 204.084,418.502
        			"/>
        		<path d="M204.084,486.022v73.185c0,3.006,1.802,4.803,4.805,4.803
        			h25.484l10.556-14.234V485.32l-18.222-29.812L204.084,486.022z"/>
        		<path d="M240.125,564.01c3.303,0,4.805-1.797,4.805-4.803v-9.432
        			l-10.556,14.234H240.125z"/>
        		<path d="M398.394,323.738h-31.235c-3.003,0-4.806,1.802-4.806,4.806
        			v24.062l12.999,21.265l27.847-37.558v-7.769C403.199,325.54,401.696,323.738,398.394,323.738z"/>
        		<polygon points="403.199,419.422 403.199,336.312 375.352,373.87
        			"/>
        		<polygon points="362.353,391.403 375.352,373.87 362.353,352.605
        			"/>
        		<polygon points="403.199,505.197 403.199,446.174 383.423,472.847
        			"/>
        		<polygon points="362.353,391.403 362.353,438.379 383.423,472.847
        			403.199,446.174 403.199,419.422 375.352,373.87 		"/>
        		<polygon points="362.353,501.263 383.423,472.847 362.353,438.379
        			"/>
        		<polygon points="403.199,524.365 403.199,505.197 383.423,472.847
        			362.353,501.263 362.353,523.773 386.948,564.01 416.542,564.01 428.935,547.294 414.917,524.365 		"/>
        		<polygon points="416.542,564.01 439.152,564.01 428.935,547.294 		"/>
        		<path d="M474.681,524.365h-7.331l9.653,15.79l2.484-3.353v-7.628
        			C479.486,526.169,477.984,524.365,474.681,524.365z"/>
        		<polygon points="479.486,544.216 479.486,536.803 477.002,540.155
        			"/>
        		<polygon points="445.937,524.365 428.935,547.294 439.152,564.01
        			459.314,564.01 477.002,540.155 467.35,524.365 		"/>
        		<path d="M459.314,564.01h15.366c3.303,0,4.806-1.498,4.806-4.803
        			v-14.991l-2.484-4.061L459.314,564.01z"/>
        		<polygon points="428.935,547.294 445.937,524.365 414.917,524.365
        			"/>
        		<path d="M362.353,559.207c0,3.305,1.802,4.803,4.806,4.803h19.79
        			l-24.595-40.236V559.207z"/>
        		<path d="M463.86,323.738c-4.505,0-6.608,2.702-4.806,6.908
        			l10.633,25.854l24.288-32.758c-0.028-0.001-0.053-0.005-0.08-0.005H463.86z"/>
        		<polygon points="542.468,394.169 534.439,414.74 531.95,408.357
        			507.72,441.037 527.048,472.658 566.922,418.877 547.593,387.255 		"/>
        		<polygon points="527.048,472.658 543.149,498.995 554.863,483.194
        			554.863,464.297 567.113,434.509 568.213,420.986 566.922,418.877 		"/>
        		<polygon points="554.863,518.159 554.863,483.194 543.149,498.995
        			"/>
        		<path d="M493.975,323.743l-24.288,32.758l28.049,68.207l9.983,16.329
        			l24.23-32.68l-31.147-79.813C499.61,325.266,497.527,323.766,493.975,323.743z"/>
        		<polygon points="542.468,394.169 547.593,387.255 546.112,384.832
        			"/>
        		<polygon points="567.113,434.509 570.88,425.35 568.213,420.986 		"/>
        		<polygon points="587.23,333.798 594.688,323.738 581.081,323.738
        			"/>
        		<path d="M605.021,323.738h-10.332l-7.458,10.06l12.733,20.83
        			l9.862-23.981C611.628,326.44,609.526,323.738,605.021,323.738z"/>
        		<polygon points="576.117,323.738 573.83,351.87 587.23,333.798
        			581.081,323.738 		"/>
        		<polygon points="573.83,351.87 568.564,416.66 581.788,398.826
        			599.963,354.628 587.23,333.798 		"/>
        		<polygon points="568.213,420.986 570.88,425.35 581.788,398.826
        			568.564,416.66 		"/>
        		<path d="M568.079,328.544l-21.966,56.288l1.481,2.423l26.237-35.385
        			l2.287-28.132h-1.13C571.382,323.738,569.28,325.24,568.079,328.544z"/>
        		<polygon points="566.922,418.877 568.564,416.66 573.83,351.87
        			547.593,387.255 		"/>
        		<polygon points="568.213,420.986 568.564,416.66 566.922,418.877
        			"/>
        		<polygon points="505.618,443.869 507.72,441.037 497.736,424.708
        			"/>
        		<polygon points="505.618,443.869 514.017,464.297 514.017,490.231
        			527.048,472.658 507.72,441.037 		"/>
        		<polygon points="514.017,490.231 514.017,538.285 543.149,498.995
        			527.048,472.658 		"/>
        		<path d="M514.017,538.285v19.719c0,3.908,2.104,6.006,6.008,6.006
        			h23.989l10.849-14.632v-31.219l-11.714-19.164L514.017,538.285z"/>
        		<path d="M548.856,564.01c3.905,0,6.006-2.098,6.006-6.006v-8.626
        			l-10.849,14.632H548.856z"/>
        		<polygon points="706.52,517.726 735.266,478.951 706.52,431.927 		"/>
        		<path d="M759.679,524.365c-7.808,0-12.013-3.903-12.013-12.01v-13.12
        			l-12.4-20.284l-28.746,38.774v3.036c0,3.252,0.227,6.305,0.629,9.202l17.124,28.015c6.775,3.979,15.402,6.031,25.796,6.031h30.125
        			l3.875-5.227l-21.042-34.418H759.679z"/>
        		<path d="M780.193,564.01h3.514c1.195,0,2.334-0.065,3.481-0.121
        			l-3.121-5.105L780.193,564.01z"/>
        		<path d="M724.272,557.979l-17.124-28.015
        			C708.955,542.968,714.768,552.389,724.272,557.979z"/>
        		<path d="M800.76,326.073l15.418,9.165
        			C812.129,331.051,806.977,327.976,800.76,326.073z"/>
        		<path d="M800.76,326.073c-5.015-1.536-10.697-2.335-17.052-2.335
        			h-1.732l30.898,50.544l13.25-17.872c-1.441-8.807-4.816-15.873-9.945-21.172L800.76,326.073z"/>
        		<path d="M812.873,374.282l14.083,23.036v-30.331
        			c0-3.769-0.292-7.284-0.833-10.577L812.873,374.282z"/>
        		<path d="M790.768,404.096c0.415,0.078,0.856,0.133,1.35,0.133h30.034
        			c3.004,0,4.804-1.8,4.804-4.807v-2.104l-14.083-23.036L790.768,404.096z"/>
        		<path d="M708.491,351.25l21.229,34.727l46.145-62.238h-25.796
        			C727.661,323.738,713.427,333.231,708.491,351.25z"/>
        		<path d="M775.865,323.738l-46.145,62.238l17.946,29.359v-39.938
        			c0-7.812,3.905-12.014,12.013-12.014h15.619c7.809,0,12.014,4.202,12.014,12.014v24.023c0,2.559,1.099,4.228,3.456,4.674
        			l22.105-29.813l-30.898-50.544H775.865z"/>
        		<path d="M706.52,366.987v50.28l23.2-31.291l-21.229-34.727
        			C707.208,355.935,706.52,361.162,706.52,366.987z"/>
        		<polygon points="706.52,417.268 706.52,431.927 735.266,478.951
        			747.666,462.229 747.666,415.336 729.72,385.977 		"/>
        		<polygon points="747.666,499.235 747.666,462.229 735.266,478.951
        			"/>
        		<path d="M784.068,558.783l3.121,5.105
        			c18.737-0.893,31.254-9.074,36.682-23.896L812.18,520.87L784.068,558.783z"/>
        		<path d="M787.312,488.326v24.029c0,8.106-4.205,12.01-12.014,12.01
        			h-12.272l21.042,34.418l28.112-37.913l-22.534-36.864C788.068,484.724,787.312,486.222,787.312,488.326z"/>
        		<path d="M822.151,483.521h-30.034c-0.988,0-1.798,0.179-2.471,0.484
        			l22.534,36.864l14.775-19.934v-12.61C826.956,485.324,825.155,483.521,822.151,483.521z"/>
        		<path d="M823.871,539.992c2.018-5.508,3.084-11.907,3.084-19.23
        			v-19.825L812.18,520.87L823.871,539.992z"/>
        		<path d="M961.161,330.81l14.899,24.376
        			C974.04,344.259,969.021,336.079,961.161,330.81z"/>
        		<path d="M863.072,341.175c-3.047,5.103-4.967,11.293-5.723,18.535
        			l26.785,15.92L863.072,341.175z"/>
        		<path d="M857.349,359.71c-0.241,2.318-0.369,4.741-0.369,7.277v79.446
        			l4.694,7.678l35.25-47.539v-10.018l-12.79-20.925L857.349,359.71z"/>
        		<polygon points="896.924,511.774 896.924,501.574 893.506,506.186
        			"/>
        		<polygon points="893.506,506.186 896.924,501.574 896.924,406.572
        			861.674,454.111 		"/>
        		<path d="M938.372,513.252c0,8.111-4.206,12.015-12.015,12.015h-17.418
        			c-1.504,0-2.832-0.193-4.063-0.484l19.2,31.407l38.607-52.068l-24.311-39.768V513.252z"/>
        		<path d="M924.075,556.189l4.78,7.82h5.012
        			c25.985,0,40.743-12.752,42.931-36.796l-14.115-23.093L924.075,556.189z"/>
        		<path d="M977.116,393.415v-26.428c0-4.248-0.381-8.158-1.056-11.802
        			l-14.899-24.376c-1.774-1.188-3.694-2.226-5.751-3.12l-25.997,35.061c5.832,1.005,8.958,5.033,8.958,11.747v33.373l19.453,11.562
        			L977.116,393.415z"/>
        		<polygon points="957.825,419.432 938.372,407.87 938.372,445.669
        			"/>
        		<polygon points="977.116,393.415 957.825,419.432 977.116,430.899
        			"/>
        		<polygon points="957.825,419.432 938.372,445.669 938.372,464.354
        			962.683,504.121 977.116,484.658 977.116,430.899 		"/>
        		<path d="M976.798,527.214c0.188-2.08,0.318-4.209,0.318-6.452v-36.104
        			l-14.434,19.463L976.798,527.214z"/>
        		<path d="M896.924,374.497c0-7.812,4.204-12.015,12.014-12.015h17.418
        			c1.097,0,2.104,0.104,3.057,0.268l25.997-35.061c-5.989-2.605-13.173-3.951-21.543-3.951h-33.639
        			c-17.783,0-30.312,5.971-37.157,17.437l21.062,34.455l12.79,7.603V374.497z"/>
        		<polygon points="896.924,383.232 884.134,375.63 896.924,396.555
        			"/>
        		<polygon points="856.98,460.445 861.674,454.111 856.98,446.434 		"/>
        		<path d="M856.98,460.445v60.316c0,10.635,2.136,19.394,6.303,26.18
        			l30.223-40.756l-31.832-52.074L856.98,460.445z"/>
        		<path d="M896.924,513.252v-1.478l-3.418-5.589l-30.223,40.756
        			c6.893,11.23,19.351,17.068,36.946,17.068h18.052l5.795-7.82l-19.2-31.407C899.708,523.56,896.924,519.8,896.924,513.252z"/>
        		<polygon points="918.28,564.01 928.855,564.01 924.075,556.189 		"/>
        		<polygon points="1007.143,406.031 1007.143,444.155 1019.918,426.927
        					"/>
        		<polygon points="1047.988,472.847 1019.918,426.927 1007.143,444.155
        			1007.143,448.746 1047.988,473.024 		"/>
        		<polygon points="1007.143,448.746 1007.143,535.641 1047.988,480.551
        			1047.988,473.024 		"/>
        		<path d="M1007.143,559.207c0,3.006,1.802,4.803,4.804,4.803h31.235
        			c3.304,0,4.807-1.797,4.807-4.803v-78.656l-40.846,55.09V559.207z"/>
        		<path d="M1095.44,560.108c0.902,2.7,2.703,3.901,5.407,3.901h2.869
        			l-16.617-27.182L1095.44,560.108z"/>
        		<polygon points="1028.777,323.738 1024.592,323.738 1026.483,326.832
        					"/>
        		<path d="M1062.2,369.901l-15.112-42.26
        			c-0.902-2.702-2.704-3.903-5.407-3.903h-12.903l-2.294,3.094l30.57,50.008L1062.2,369.901z"/>
        		<polygon points="1062.2,369.901 1057.054,376.84 1075.438,406.916
        			"/>
        		<path d="M1135.388,359.887l-22.099-36.148h-13.943
        			c-3.304,0-4.805,1.802-4.805,4.806v89.217l40.847-55.089V359.887z"/>
        		<polygon points="1135.388,464.71 1135.388,362.672 1094.541,417.761
        			1094.541,438.164 1121.894,482.91 		"/>
        		<polygon points="1135.388,464.71 1121.894,482.91 1135.388,504.982
        			"/>
        		<path d="M1135.388,328.544c0-3.004-1.804-4.806-4.806-4.806h-17.293
        			l22.099,36.148V328.544z"/>
        		<path d="M1024.592,323.738h-12.646c-3.002,0-4.804,1.802-4.804,4.806
        			v24.372l19.341-26.084L1024.592,323.738z"/>
        		<polygon points="1057.054,376.84 1026.483,326.832 1007.143,352.916
        			1007.143,406.031 1019.918,426.927 		"/>
        		<polygon points="1047.988,431.859 1049.49,431.859 1060.763,463.319
        			1084.271,431.615 1075.438,406.916 1057.054,376.84 1019.918,426.927 1047.988,472.847 		"/>
        		<polygon points="1121.894,482.91 1094.541,438.164 1094.541,455.288
        			1092.738,455.288 1084.271,431.615 1060.763,463.319 1068.639,485.3 1104.372,506.543 		"/>
        		<polygon points="1104.372,506.543 1068.639,485.3 1085.411,532.114
        			"/>
        		<path d="M1130.582,564.01c3.002,0,4.806-1.797,4.806-4.803v-14.721
        			l-14.477,19.523H1130.582z"/>
        		<polygon points="1135.388,504.982 1121.894,482.91 1104.372,506.543
        			1135.388,524.977 		"/>
        		<polygon points="1104.372,506.543 1085.411,532.114 1087.1,536.828
        			1103.717,564.01 1120.911,564.01 1135.388,544.486 1135.388,524.977 		"/>
        		<path d="M1239.909,323.738h-31.249c-4.602,0-8.824,0.447-12.728,1.253
        			l19.453,31.823L1239.909,323.738z"/>
        		<path d="M1232.687,363.385c8.109,0,12.016,4.202,12.016,12.014v21.65
        			l37.287-50.291l-6.982-11.42c-7.284-7.636-18.25-11.6-32.709-11.6h-2.389l-24.523,33.076l4.018,6.57H1232.687z"/>
        		<path d="M1249.507,403.627h31.236c3.3,0,4.805-1.799,4.805-4.805
        			v-31.835c0-7.457-1.078-13.964-3.141-19.545l-0.418-0.685l-37.287,50.291v1.773
        			C1244.702,401.828,1246.502,403.627,1249.507,403.627z"/>
        		<path d="M1282.071,346.647c-1.725-4.376-4.066-8.168-7.064-11.31
        			l6.982,11.42L1282.071,346.647z"/>
        		<path d="M1282.071,346.647l-0.082,0.11l0.418,0.685
        			C1282.306,347.168,1282.177,346.918,1282.071,346.647z"/>
        		<path d="M1215.386,356.814l-19.453-31.823
        			c-19.798,4.091-30.521,18.412-30.521,41.996v42.015l5.097,8.339L1215.386,356.814z"/>
        		<path d="M1199.787,457.627l25.591-34.517l-19.121-6.268v-41.444
        			c0-7.812,4.505-12.014,12.013-12.014h1.134l-4.018-6.57l-44.877,60.526l23.456,38.365L1199.787,457.627z"/>
        		<polygon points="1244.702,498.604 1276.142,456.201 1263.563,435.625
        			1225.378,423.11 1199.787,457.627 1244.702,472.408 		"/>
        		<path d="M1285.523,521.624l-28.099,37.899l1.417,2.318
        			C1276.099,556.848,1285.282,543.182,1285.523,521.624z"/>
        		<path d="M1285.523,521.624c0.005-0.291,0.024-0.571,0.024-0.862
        			v-49.174l-9.406-15.387l-31.439,42.402v13.752c0,6.956-3.116,10.792-8.928,11.749l21.65,35.419L1285.523,521.624z"/>
        		<path d="M1285.548,451.386c0-2.385-0.498-4.325-1.419-5.962
        			l-7.987,10.777l9.406,15.387V451.386z"/>
        		<path d="M1284.129,445.424c-1.284-2.277-3.416-3.928-6.39-5.152
        			l-14.176-4.646l12.578,20.576L1284.129,445.424z"/>
        		<polygon points="1165.412,409.002 1165.412,424.214 1170.509,417.341
        					"/>
        		<path d="M1173.521,448.98l20.444,6.726l-23.456-38.365l-5.097,6.873
        			v13.652C1165.412,443.574,1168.115,447.183,1173.521,448.98z"/>
        		<path d="M1170.216,484.424c-3.003,0-4.804,1.498-4.804,4.805v14.761
        			l14.508-19.565H1170.216z"/>
        		<path d="M1225.604,524.365h-7.334c-7.508,0-12.013-3.903-12.013-12.01
        			v-23.127c0-3.307-1.503-4.805-4.807-4.805h-21.53l-14.508,19.565v16.772c0,10.396,2.091,18.986,6.119,25.701l26.158,15.545
        			L1225.604,524.365z"/>
        		<path d="M1171.531,546.463c5.268,8.779,13.854,14.349,25.454,16.499
        			l0.704-0.954L1171.531,546.463z"/>
        		<path d="M1257.425,559.523l-2.42,3.259
        			c1.318-0.271,2.599-0.586,3.837-0.94L1257.425,559.523z"/>
        		<path d="M1208.66,564.01h33.638c4.602,0,8.828-0.428,12.707-1.228
        			l2.42-3.259l-21.65-35.419c-0.962,0.158-1.979,0.261-3.088,0.261h-7.083l-27.914,37.643l2.295,1.366
        			C1202.736,563.746,1205.586,564.01,1208.66,564.01z"/>
        		<path d="M1199.984,563.374l-2.295-1.366l-0.704,0.954
        			C1197.948,563.14,1198.981,563.236,1199.984,563.374z"/>
        		<polygon points="1374.354,323.738 1357.794,323.738 1365.277,335.979
        					"/>
        		<polygon points="1384.056,363.385 1402.848,363.385 1423.289,335.814
        			1415.906,323.738 1374.354,323.738 1365.277,335.979 1384.056,366.696 		"/>
        		<path d="M1423.702,358.58v-22.091l-0.413-0.675l-20.441,27.57h16.049
        			C1422.198,363.385,1423.702,361.58,1423.702,358.58z"/>
        		<path d="M1423.702,335.258v-6.714c0-3.004-1.504-4.806-4.806-4.806
        			h-2.99l7.383,12.076L1423.702,335.258z"/>
        		<polygon points="1423.702,335.258 1423.289,335.814 1423.702,336.489
        					"/>
        		<path d="M1308.369,363.385h34.839v2.361l22.069-29.767l-7.483-12.241
        			h-49.425c-3.004,0-4.804,1.802-4.804,4.806v30.036C1303.565,361.58,1305.365,363.385,1308.369,363.385z"/>
        		<polygon points="1384.056,366.696 1365.277,335.979 1343.208,365.746
        			1343.208,443.824 1384.056,388.73 		"/>
        		<polygon points="1384.056,463.165 1384.056,388.73 1343.208,443.824
        			1343.208,446.901 1367.117,486.013 		"/>
        		<polygon points="1384.056,463.165 1367.117,486.013 1384.056,513.721
        					"/>
        		<polygon points="1343.208,446.901 1343.208,518.261 1367.117,486.013
        					"/>
        		<path d="M1348.015,564.01h16.057l19.984-26.952v-23.337
        			l-16.938-27.708l-23.909,32.248v40.946C1343.208,562.512,1345.012,564.01,1348.015,564.01z"/>
        		<path d="M1384.056,559.207v-22.149l-19.984,26.952h15.18
        			C1382.552,564.01,1384.056,562.512,1384.056,559.207z"/>
        		<polygon points="1487.435,323.738 1483.018,323.738 1485.014,327.004
        					"/>
        		<path d="M1524.151,348.105l-4.353-20.162
        			c-0.596-3.004-2.399-4.205-5.105-4.205h-27.259l-2.421,3.266l24.758,40.497L1524.151,348.105z"/>
        		<polygon points="1524.151,348.105 1509.771,367.501 1538.474,414.459
        					"/>
        		<path d="M1483.018,323.738h-10.068c-2.707,0-4.504,1.201-5.106,4.205
        			l-6.767,31.347l23.938-32.286L1483.018,323.738z"/>
        		<polygon points="1490.522,393.461 1472.99,417.111 1483.139,433.722
        					"/>
        		<polygon points="1490.522,393.461 1493.67,376.295 1495.571,386.654
        			1509.771,367.501 1485.014,327.004 1461.076,359.29 1454.958,387.619 1472.99,417.111 		"/>
        		<polygon points="1539.243,418.016 1538.474,414.459 1509.771,367.501
        			1495.571,386.654 1508.839,459.018 		"/>
        		<polygon points="1512.111,481.117 1529.572,509.68 1552.39,478.91
        			1539.243,418.016 1508.839,459.018 1512.896,481.117 		"/>
        		<path d="M1565.453,564.01c3.603,0,4.806-1.797,4.204-5.104
        			l-17.268-79.995l-22.817,30.77l33.216,54.33H1565.453z"/>
        		<polygon points="1472.99,417.111 1454.958,387.619 1439.793,457.877
        			1441.136,460.072 		"/>
        		<polygon points="1492.459,481.117 1474.452,481.117 1483.139,433.722
        			1472.99,417.111 1441.136,460.072 1471.379,509.543 		"/>
        		<polygon points="1520.4,515.957 1521.327,520.803 1529.572,509.68
        			1512.111,481.117 1492.459,481.117 1471.379,509.543 1475.297,515.957 		"/>
        		<path d="M1533.618,564.01h29.17l-33.216-54.33l-8.245,11.123
        			l7.484,39.005C1529.414,562.512,1530.912,564.01,1533.618,564.01z"/>
        		<polygon points="1439.793,457.877 1438.573,463.527 1441.136,460.072
        					"/>
        		<path d="M1421.889,564.01h9.109l40.381-54.467l-30.243-49.471
        			l-2.562,3.455l-20.588,95.378C1417.083,562.213,1418.586,564.01,1421.889,564.01z"/>
        		<path d="M1458.835,559.808l8.107-43.851h8.354l-3.918-6.414
        			l-40.381,54.467h23.026C1456.731,564.01,1458.234,562.512,1458.835,559.808z"/>
        		<path d="M1721.924,328.544c0-3.004-1.805-4.806-4.806-4.806h-21.114
        			l25.92,17.687V328.544z"/>
        		<polygon points="1634.525,501.289 1634.525,471.751 1599.806,414.959
        			1593.681,423.221 1593.681,504.764 1610.974,533.053 		"/>
        		<path d="M1634.525,559.207v-57.918l-23.552,31.764l18.914,30.943
        			C1633.067,563.935,1634.525,562.156,1634.525,559.207z"/>
        		<polygon points="1593.681,404.938 1593.681,423.221 1599.806,414.959
        					"/>
        		<path d="M1633.622,327.642c-0.901-2.702-2.7-3.903-5.405-3.903h-0.112
        			l9.936,16.253L1633.622,327.642z"/>
        		<path d="M1721.924,383.411v-14.577l-27.571-45.096h-8.468
        			c-3.307,0-4.805,1.802-4.805,4.806v45.63l36.496,15.096L1721.924,383.411z"/>
        		<polygon points="1717.576,389.27 1681.08,374.174 1681.08,410.397
        			1690.496,425.802 		"/>
        		<polygon points="1721.924,383.411 1717.576,389.27 1721.924,391.066
        					"/>
        		<polygon points="1721.924,454.331 1721.924,391.066 1717.576,389.27
        			1690.496,425.802 1714.255,464.669 		"/>
        		<polygon points="1721.924,454.331 1714.255,464.669 1721.924,477.214
        					"/>
        		<polygon points="1721.924,341.425 1696.004,323.738 1694.353,323.738
        			1721.924,368.834 		"/>
        		<path d="M1609.144,323.738h-10.658c-3.006,0-4.805,1.802-4.805,4.806
        			v9.476l3.729,1.544L1609.144,323.738z"/>
        		<polygon points="1593.681,338.02 1593.681,344.596 1597.41,339.563
        			"/>
        		<polygon points="1643.686,355.777 1638.04,339.991 1628.104,323.738
        			1609.144,323.738 1597.41,339.563 1642.024,358.017 		"/>
        		<polygon points="1642.024,358.017 1597.41,339.563 1593.681,344.596
        			1593.681,404.938 1599.806,414.959 		"/>
        		<polygon points="1643.686,355.777 1642.024,358.017 1644.913,359.211
        					"/>
        		<polygon points="1634.525,431.859 1636.028,431.859 1652.308,477.3
        			1675.812,445.602 1644.913,359.211 1642.024,358.017 1599.806,414.959 1634.525,471.751 		"/>
        		<polygon points="1714.255,464.669 1690.496,425.802 1681.08,438.498
        			1681.08,455.288 1679.275,455.288 1675.812,445.602 1652.308,477.3 1669.437,525.114 		"/>
        		<path d="M1690.924,564.01h26.194c1.02,0,1.891-0.223,2.609-0.615
        			l2.003-2.7c0.112-0.464,0.193-0.948,0.193-1.487v-81.993l-7.669-12.545l-44.818,60.445l3.252,9.063L1690.924,564.01z"/>
        		<path d="M1721.73,560.694l-2.003,2.7
        			C1720.742,562.839,1721.429,561.917,1721.73,560.694z"/>
        		<polygon points="1681.08,410.397 1681.08,438.498 1690.496,425.802
        			"/>
        		<path d="M1687.382,564.01h3.542l-18.235-29.832l9.287,25.931
        			C1682.879,562.809,1684.682,564.01,1687.382,564.01z"/>
        		<polygon points="1593.681,504.764 1593.681,556.379 1610.974,533.053
        					"/>
        		<path d="M1598.485,564.01h31.234c0.062,0,0.111-0.009,0.168-0.014
        			l-18.914-30.943l-17.293,23.326v2.828C1593.681,562.213,1595.479,564.01,1598.485,564.01z"/>
        		<path d="M1750.747,363.385h3.349l-8.152-5.567v0.763
        			C1745.943,361.58,1747.74,363.385,1750.747,363.385z"/>
        		<polygon points="1818.024,430.819 1785.583,417.402 1785.583,472.975
        			1786.118,473.85 		"/>
        		<polygon points="1826.433,419.48 1826.433,412.751 1785.583,384.873
        			1785.583,417.402 1818.024,430.819 		"/>
        		<polygon points="1826.433,419.48 1818.024,430.819 1826.433,434.3
        			"/>
        		<polygon points="1826.433,505.996 1826.433,434.3 1818.024,430.819
        			1786.118,473.85 1815.105,521.272 		"/>
        		<polygon points="1826.433,505.996 1815.105,521.272 1826.433,539.794
        					"/>
        		<polygon points="1785.583,472.975 1785.583,474.574 1786.118,473.85
        					"/>
        		<path d="M1790.394,564.01h31.234c3.301,0,4.805-1.498,4.805-4.803
        			v-19.413l-11.327-18.521l-29.323,39.55C1786.307,562.992,1787.937,564.01,1790.394,564.01z"/>
        		<path d="M1815.105,521.272l-28.987-47.423l-0.535,0.725v84.633
        			c0,0.605,0.082,1.131,0.199,1.615L1815.105,521.272z"/>
        		<polygon points="1818.748,323.738 1784.202,323.738 1799.814,349.274
        					"/>
        		<path d="M1826.433,363.385h34.841c3.303,0,4.806-1.805,4.806-4.805
        			v-12.335l-13.758-22.507h-33.573l-18.934,25.536l26.618,43.541V363.385z"/>
        		<path d="M1866.079,328.544c0-3.004-1.503-4.806-4.806-4.806h-8.952
        			l13.758,22.507V328.544z"/>
        		<path d="M1750.747,323.738c-3.007,0-4.804,1.802-4.804,4.806v22.471
        			l20.223-27.276H1750.747z"/>
        		<polygon points="1785.583,363.385 1785.583,368.47 1799.814,349.274
        			1784.202,323.738 1766.166,323.738 1745.943,351.015 1745.943,357.817 1754.096,363.385 		"/>
        		<polygon points="1826.433,392.815 1799.814,349.274 1785.583,368.47
        			1785.583,384.873 1826.433,412.751 		"/>
        	</g>
        </g>

      </svg>
    )
  }
}
